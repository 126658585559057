import { ProfileGender, ProfileMainCategory } from '../models/profile.model';
import { MediaImageModel } from '@eros-api/models/media.model';

export class AggregatorCategoryModel {
  id!: number;
  parentId!: number | null;
  name!: string;
  headlinerTerm!: string;
  term!: string;
  displayOn!: 0 | 1;
  categoryId!: number;
  displayOrder!: number;
  categoryType!: string;
  locationId!: number;
  presentationCategoryId!: number;
  numTiers!: number;
  numBanners!: number;
  displayValue!: string;
  splashPageBase!: string;
  sectionPageBase!: string;
  count!: number;

  static fromJSON(json: any): AggregatorCategoryModel {
    const model = AggregatorCategoryModel.empty();

    Object.assign(model, {
      id: json.id,
      parentId: json.parent_id ? json.parent_id : null,
      name: json.name,
      headlinerTerm: json.headliner_term,
      term: json.term,
      displayOn: json.display_on,
      categoryId: json.category_id,
      displayOrder: json.display_order,
      categoryType: json.category_type,
      locationId: json.location_id,
      presentationCategoryId: json.presentation_category_id,
      numTiers: json.num_tiers,
      numBanners: json.num_banners,
      displayValue: json.display_value,
      splashPageBase: json.splash_page_base,
      sectionPageBase: json.section_page_base
    } as AggregatorCategoryModel);

    return model;
  }

  static empty(): AggregatorCategoryModel {
    return new AggregatorCategoryModel();
  }
}

export interface IAggregatorGeography {
  id: number;
  name: string;
  keyword: string;
  iso: string;
  latitude: number;
  longitude: number;
  timezoneId: number | null;
  timezone: string | null;
  aliasOf: number | null;
  isMapVisible: number;
  site: string | null;
}

export class AggregatorGeographyModel implements IAggregatorGeography {
  id!: number;
  name!: string;
  keyword!: string;
  iso!: string;
  latitude!: number;
  longitude!: number;
  timezoneId!: number | null;
  timezone!: string | null;
  aliasOf!: number | null;
  isMapVisible!: number;
  site!: string | null;

  static fromJSON(json: any): AggregatorGeographyModel {
    const model = AggregatorGeographyModel.empty();

    Object.assign(model, {
      id: json.id,
      name: json.name,
      keyword: json.keyword,
      iso: json.iso,
      latitude: json.latitude,
      longitude: json.longitude,
      timezoneId: json.timezone_id ? json.timezone_id : null,
      timezone: json.timezone ? json.timezone : null,
      aliasOf: json.alias_of ? json.alias_of : null,
      isMapVisible: json.is_map_visible,
      site: json.site ? json.site : null
    } as AggregatorGeographyModel);

    return model;
  }

  static empty(): AggregatorGeographyModel {
    return new AggregatorGeographyModel();
  }
}

export class AggregatorGeographyFullModel implements IAggregatorGeography {
  id!: number;
  name!: string;
  keyword!: string;
  iso!: string;
  latitude!: number;
  longitude!: number;
  timezoneId!: number | null;
  timezone!: string | null;
  aliasOf!: number | null;
  isMapVisible!: number;
  site!: string | null;
  subNodes!: Array<Record<string, any>>;

  static fromJSON(json: any): AggregatorGeographyFullModel {
    const model = AggregatorGeographyFullModel.empty();

    Object.assign(model, {
      id: json.id,
      name: json.name,
      keyword: json.keyword,
      iso: json.iso,
      latitude: json.latitude,
      longitude: json.longitude,
      timezoneId: json.timezone_id ? json.timezone_id : null,
      timezone: json.timezone ? json.timezone : null,
      aliasOf: json.alias_of ? json.alias_of : null,
      isMapVisible: json.is_map_visible,
      site: json.site ? json.site : null,
      subNodes: json.sub_nodes
    } as AggregatorGeographyFullModel);

    return model;
  }

  static empty(): AggregatorGeographyFullModel {
    return new AggregatorGeographyFullModel();
  }
}

export class AggregatorGeographyNodeModel {
  id!: number;
  name!: string;
  count!: number;
  type!: string;
  iso!: string;
  aliasOf!: number | null;
  isMapVisible!: number;
  distance!: number | null;

  static fromJSON(json: any): AggregatorGeographyNodeModel {
    const model = AggregatorGeographyNodeModel.empty();

    Object.assign(model, {
      id: json.id,
      name: json.name,
      count: json.count,
      type: json.type,
      iso: json.iso,
      aliasOf: json.alias_of ? json.alias_of : null,
      isMapVisible: json.is_map_visible,
      distance: json.distance ? json.distance : null
    } as AggregatorGeographyNodeModel);

    return model;
  }

  static empty(): AggregatorGeographyNodeModel {
    return new AggregatorGeographyNodeModel();
  }
}

export class AggregatorLocationPresentationCategoryModel {
  id!: number;
  locationId!: number;
  presentationCategoryId!: number;
  numTiers!: number;
  numBanners!: number;
  displayValue!: string;
  splashPageBase!: string;
  sectionPageBase!: string;

  static fromJSON(json: any): AggregatorLocationPresentationCategoryModel {
    const model = AggregatorLocationPresentationCategoryModel.empty();

    Object.assign(model, {
      id: json.id,
      locationId: json.location_id,
      presentationCategoryId: json.presentation_category_id,
      numTiers: json.num_tiers,
      numBanners: json.num_banners,
      displayValue: json.display_value,
      splashPageBase: json.splash_page_base,
      sectionPageBase: json.section_page_base
    } as AggregatorLocationPresentationCategoryModel);

    return model;
  }

  static empty(): AggregatorLocationPresentationCategoryModel {
    return new AggregatorLocationPresentationCategoryModel();
  }
}

export type AggregatorLocationPresentationCategoryCountReqPayload = {
  locationId: number;
  mainCategoryId: number;
  secondaryCategoryId?: number;
};

export type AggregatorLocationPresentationCategoryCountResPayload = {
  location_id?: number;
  presentation_category_id?: number;
  count: number;
};

export class AggregatorPresentationCategoryModel {
  id!: number;
  parentId!: number | null;
  name!: string;
  headlinerTerm!: string;
  term!: string;
  displayOn!: 0 | 1;
  categoryId!: number;
  displayOrder!: number;
  categoryType!: string;
  index?: number;

  static fromJSON(json: any): AggregatorPresentationCategoryModel {
    const model = AggregatorPresentationCategoryModel.empty();

    Object.assign(model, {
      id: json.id,
      parentId: json.parent_id ? json.parent_id : null,
      name: json.name,
      headlinerTerm: json.headliner_term,
      term: json.term,
      displayOn: json.display_on,
      categoryId: json.category_id,
      displayOrder: json.display_order,
      categoryType: json.category_type
    } as AggregatorPresentationCategoryModel);

    return model;
  }

  static empty(): AggregatorPresentationCategoryModel {
    return new AggregatorPresentationCategoryModel();
  }
}

export class AggregatorSiteModel {
  id!: number;
  categoryId!: number | null;
  locationId!: number;
  name!: string;
  domainName!: string;
  satelliteDomain!: string;
  defaultLocale!: string;
  type!: string;
  code!: string;
  keyword!: string;
  numDaysWhatsNew!: number;
  isFoldedDomain!: 0 | 1;
  gmtOffset!: number;

  static fromJSON(json: any): AggregatorSiteModel {
    const model = AggregatorSiteModel.empty();

    Object.assign(model, {
      id: json.id,
      categoryId: json.category_id ? json.category_id : null,
      locationId: json.location_id,
      name: json.name,
      domainName: json.domain_name,
      satelliteDomain: json.satellite_domain,
      defaultLocale: json.default_locale,
      type: json.type,
      code: json.code,
      keyword: json.keyword,
      numDaysWhatsNew: json.num_days_whatsnew,
      isFoldedDomain: json.is_folded_domain,
      gmtOffset: json.gmt_offset
    } as AggregatorSiteModel);

    return model;
  }

  static empty(): AggregatorSiteModel {
    return new AggregatorSiteModel();
  }
}

export class AggregatorTrendingMetaModel {
  siteId!: number;
  title!: string;
  description!: string;
  contentTitle!: string;
  contentDescription!: string;

  static fromJSON(json: any): AggregatorTrendingMetaModel {
    const model = AggregatorTrendingMetaModel.empty();

    Object.assign(model, {
      siteId: json.site_id,
      title: json.title,
      description: json.description,
      contentTitle: json.content_title,
      contentDescription: json.content_description
    } as AggregatorTrendingMetaModel);

    return model;
  }

  static empty(): AggregatorTrendingMetaModel {
    return new AggregatorTrendingMetaModel();
  }
}

export class AggregatorV2SiteModel {
  id!: number;
  category!: string;
  locationId!: number;
  name!: string;
  domainName!: string;
  satelliteDomain!: string;
  defaultLocale!: string;
  code!: string;
  keyword!: string;
  numDaysWhatsNew!: number;
  isFoldedDomain!: 0 | 1;
  gmtOffset!: number;

  static fromJSON(json: any): AggregatorV2SiteModel {
    const model = AggregatorV2SiteModel.empty();

    Object.assign(model, {
      id: json.id,
      category: json.category,
      locationId: json.location_id,
      name: json.name,
      domainName: json.domain_name,
      satelliteDomain: json.satellite_domain,
      defaultLocale: json.default_locale,
      code: json.code,
      keyword: json.keyword,
      numDaysWhatsNew: json.num_days_whatsnew,
      isFoldedDomain: json.is_folded_domain,
      gmtOffset: json.gmt_offset
    } as AggregatorV2SiteModel);

    return model;
  }

  static empty(): AggregatorV2SiteModel {
    return new AggregatorV2SiteModel();
  }
}

export interface IAggregatorProfileList {
  id: number;
  profileId: string;
  accountId: number;
  linkAds: number;
  showName: number;
  visitingText: string | null;
  gender: ProfileGender;
  tagline: string;
  locationTags: string | null;
  locationId: number;
  countryName: string;
  stateName: string | null;
  isVip: 0 | 1;
  isAnimated: 0 | 1;
  isVisiting: 0 | 1;
  hasVideo: 0 | 1;
  isAvailableNow: 0 | 1;
  isAuthenticated: 0 | 1;
  isAccountFrozen: 0 | 1;
  isDeleted: 0 | 1;
  isPaid: 0 | 1;
  hasProfile: 0 | 1;
  photosCount: number;
  isHidden: 0 | 1;
  isOnline: 0 | 1;
  photos: IAggregatorProfilePhoto[] | null;
  hash: string;
  ext: string;
  status: string;
  isWhatsNew: 0 | 1;
  isWhatsNewForced: 0 | 1 | null;
  whatsNewDate: Date | null;
  whatsNewEligibleDate: Date | null;
  whatsNewReason: string | null;
  dateCreated: Date;
  dateModified: Date | null;
  globalDateModified: Date | null;
  ordering: number;
  mainCategories: ProfileMainCategory[];
  isXxx: 0 | 1;
  hasAnyPs: 0 | 1;
}

export class AggregatorProfileListModel implements IAggregatorProfileList {
  id!: number;
  profileId!: string;
  accountId!: number;
  linkAds!: number;
  showName!: number;
  visitingText!: string | null;
  gender!: ProfileGender;
  tagline!: string;
  locationTags!: string | null;
  locationId!: number;
  countryName!: string;
  stateName!: string | null;
  isVip!: 0 | 1;
  isAnimated!: 0 | 1;
  isVisiting!: 0 | 1;
  hasVideo!: 0 | 1;
  isAvailableNow!: 0 | 1;
  isAuthenticated!: 0 | 1;
  isAccountFrozen!: 0 | 1;
  isDeleted!: 0 | 1;
  isPaid!: 0 | 1;
  hasProfile!: 0 | 1;
  photosCount!: number;
  isHidden!: 0 | 1;
  isOnline!: 0 | 1;
  photos!: IAggregatorProfilePhoto[] | null;
  hash!: string;
  ext!: string;
  status!: string;
  isWhatsNew!: 0 | 1;
  isWhatsNewForced!: 0 | 1 | null;
  whatsNewDate!: Date | null;
  whatsNewEligibleDate!: Date | null;
  whatsNewReason!: string | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  globalDateModified!: Date | null;
  ordering!: number;
  mainCategories!: ProfileMainCategory[];
  isXxx!: 0 | 1;
  hasAnyPs!: 0 | 1;

  static fromJSON(json: any): AggregatorProfileListModel {
    const model = AggregatorProfileListModel.empty();

    Object.assign(model, {
      id: json.id,
      profileId: json.profile_id,
      accountId: json.account_id,
      linkAds: json.link_ads,
      showName: json.showname,
      visitingText: json.visiting_text ? json.visiting_text : null,
      gender: json.gender,
      tagline: json.tagline,
      locationTags: json.location_tags ? json.location_tags : null,
      locationId: json.location_id,
      countryName: json.country_name,
      stateName: json.state_name ? json.state_name : null,
      isVip: json.is_vip,
      isAnimated: json.is_animated,
      isVisiting: json.is_visiting,
      hasVideo: json.has_video,
      isAvailableNow: json.is_available_now,
      isAuthenticated: json.is_authenticated,
      isAccountFrozen: json.is_account_frozen,
      isDeleted: json.is_deleted,
      isPaid: json.is_paid,
      hasProfile: json.has_profile,
      photosCount: json.photos_count,
      isHidden: json.is_hidden,
      isOnline: json.is_online,
      photos: json.photos ? json.photos : null,
      hash: json.hash,
      ext: json.ext,
      status: json.status,
      isWhatsNew: json.is_whatsnew,
      isWhatsNewForced: json.is_whatsnew_forced
        ? json.is_whatsnew_forced
        : null,
      whatsNewDate: json.whatsnew_date ? new Date(json.whatsnew_date) : null,
      whatsNewEligibleDate: json.whatsnew_eligible_date
        ? new Date(json.whatsnew_eligible_date)
        : null,
      whatsNewReason: json.whatsnew_reason ? json.whatsnew_reason : null,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      globalDateModified: json.global_date_modified
        ? new Date(json.global_date_modified)
        : null,
      ordering: json.ordering,
      mainCategories: json.main_categories,
      isXxx: json.is_xxx,
      hasAnyPs: json.has_any_ps
    } as AggregatorProfileListModel);

    return model;
  }

  static empty(): AggregatorProfileListModel {
    return new AggregatorProfileListModel();
  }
}

export class AggregatorProfileModel {
  id!: number;
  intOrder!: number;
  profileId!: string;
  accountId!: number;
  linkAds!: number;
  showName!: number;
  visitingText!: string | null;
  gender!: ProfileGender;
  tagline!: string;
  locationTags!: string | null;
  locationId!: number;
  countryName!: string;
  stateName!: string | null;
  isVip!: 0 | 1;
  isAnimated!: 0 | 1;
  isVisiting!: 0 | 1;
  hasVideo!: 0 | 1;
  isAvailableNow!: 0 | 1;
  isAuthenticated!: 0 | 1;
  isAccountFrozen!: 0 | 1;
  isDeleted!: 0 | 1;
  isPaid!: 0 | 1;
  hasProfile!: 0 | 1;
  photosCount!: number;
  isHidden!: 0 | 1;
  isOnline!: 0 | 1;
  photos!: IAggregatorProfilePhoto[];
  hash!: string;
  ext!: string;
  status!: string;
  isWhatsNew!: 0 | 1;
  isWhatsNewForced!: 0 | 1 | null;
  whatsNewDate!: Date | null;
  whatsNewEligibleDate!: Date | null;
  whatsNewReason!: string | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  globalDateModified!: Date | null;
  ordering!: number;
  mainCategories!: ProfileMainCategory[];
  isXxx!: 0 | 1;
  hasAnyPs!: 0 | 1;
  isSecuredPlacement!: 0 | 1;
  isRegular!: 0 | 1;
  bumpPosition!: number;
  salesAccountId?: number;
  premiumAd?: {
    id: string;
    dateActivation: Date;
    dateExpiration: Date;
  };
  premiumService?: {
    id: string;
    dateActivation: Date;
    dateExpiration: Date;
    runDateStart: Date;
    runDateEnd: Date;
  };
  revenue?: number;
  psRevenue?: number;
  salesAccountUsername?: string;
  photoHash: string;
  photoPath: string;
  photoExt: string;
  photo: MediaImageModel;

  static fromJSON(json: any): AggregatorProfileModel {
    const model = AggregatorProfileModel.empty();

    Object.assign(model, {
      id: json.id,
      intOrder: json.int_order,
      profileId: json.profile_id,
      accountId: json.account_id,
      linkAds: json.link_ads,
      showName: json.showname,
      visitingText: json.visiting_text ? json.visiting_text : null,
      gender: json.gender,
      tagline: json.tagline,
      locationTags: json.location_tags ? json.location_tags : null,
      locationId: json.location_id,
      countryName: json.country_name,
      stateName: json.state_name ? json.state_name : null,
      isVip: json.is_vip,
      isAnimated: json.is_animated,
      isVisiting: json.is_visiting,
      hasVideo: json.has_video,
      isAvailableNow: json.is_available_now,
      isAuthenticated: json.is_authenticated,
      isAccountFrozen: json.is_account_frozen,
      isDeleted: json.is_deleted,
      isPaid: json.is_paid,
      hasProfile: json.has_profile,
      photosCount: json.photos_count,
      isHidden: json.is_hidden,
      isOnline: json.is_online,
      photos: json.photos,
      hash: json.hash,
      ext: json.ext,
      status: json.status,
      isWhatsNew: json.is_whatsnew,
      isWhatsNewForced: json.is_whatsnew_forced
        ? json.is_whatsnew_forced
        : null,
      whatsNewDate: json.whatsnew_date ? new Date(json.whatsnew_date) : null,
      whatsNewEligibleDate: json.whatsnew_eligible_date
        ? new Date(json.whatsnew_eligible_date)
        : null,
      whatsNewReason: json.whatsnew_reason ? json.whatsnew_reason : null,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      globalDateModified: json.global_date_modified
        ? new Date(json.global_date_modified)
        : null,
      ordering: json.ordering,
      mainCategories: json.main_categories,
      isXxx: json.is_xxx,
      hasAnyPs: json.has_any_ps,
      isSecuredPlacement: json.is_secured_placement,
      isRegular: json.is_regular,
      bumpPosition: json.bump_position,
      photoPath: json.photo_path,
      photoHash: json.photo_hash,
      photoExt: json.photo_ext
    } as AggregatorProfileModel);

    return model;
  }

  static empty(): AggregatorProfileModel {
    return new AggregatorProfileModel();
  }
}

export interface IAggregatorProfilePhoto {
  id: string;
  hash: string;
  ext: string;
  is_main: 0 | 1;
  status: string;
}

export type AggregatorProfileReqPayload = {
  locationId: number;
  presentationCategoryIds?: number[];
  sort?: AggregatorProfileSortReqPayload;
  page?: number;
  order?: 'asc' | 'desc';
  perPage?: number;
};

export enum AggregatorProfileSortReqPayload {
  Random = 'random',
  WhatsNew = 'whatsnew',
  Any = 'any'
}

export class AggregatorProfileBacklinkModel {
  referenceId!: number;
  accountId!: number;
  website!: string;
  sourceUrl!: string | null;
  foundUrl!: string | null;
  date!: Date;
  errorCode!: string | null;

  static fromJSON(json: any): AggregatorProfileBacklinkModel {
    const model = AggregatorProfileBacklinkModel.empty();

    Object.assign(model, {
      referenceId: json.reference_id,
      accountId: json.account_id,
      website: json.website,
      sourceUrl: json.source_url ? json.source_url : null,
      foundUrl: json.found_url ? json.found_url : null,
      date: new Date(json.date),
      errorCode: json.error_code ? json.error_code : null
    } as AggregatorProfileBacklinkModel);

    return model;
  }

  static empty(): AggregatorProfileBacklinkModel {
    return new AggregatorProfileBacklinkModel();
  }
}

export type AggregatorProfileFillFeaturedReqPayload = {
  locationId: number;
  type: 'premier' | 'visiting';
  limit?: number;
  presentationCategoryId?: number;
  excludeAdIds?: string[];
};

export type AggregatorProfileFillPaidSpotReqPayload = {
  locationId?: number;
  limit: number;
  presentationCategoryId?: number;
  excludeAdIds?: string[];
};

export type AggregatorBulkOnlineProfileResPayload = {
  id: number;
  isOnline: 0 | 1;
};

export type AggregatorOnlineProfileCountResPayload = {
  accountId: number;
  isVisiting?: 0 | 1;
  isVip?: 0 | 1;
};

export type AggregatorOnlineProfileReqPayload = {
  accountId?: number;
  isVisiting?: 0 | 1;
  isVip?: 0 | 1;
};

export type AggregatorOnlineProfileResPayload = {
  id: number;
  profileId: string;
};

export type AggregatorPagingProfileReqPayload = {
  locationId: number;
  presentationCategoryIds: number[];
  profileId: number;
  sort?: AggregatorPagingProfileSort;
  order?: 'asc' | 'desc';
};

export type AggregatorPagingProfileResPayload = {
  previousId?: number;
  nextId?: number;
};

export enum AggregatorPagingProfileSort {
  random = 'random',
  WhatsNew = 'whatsnew'
}

export class AggregatorProfilePhotoModel {
  profileId!: number;
  photoId!: string;
  hash!: string;
  ext!: string;
  status!: string;
  path!: string;
  isMain!: 0 | 1;
  ordering!: number;

  static fromJSON(json: any): AggregatorProfilePhotoModel {
    const model = AggregatorProfilePhotoModel.empty();

    Object.assign(model, {
      profileId: json.profile_id,
      photoId: json.photo_id,
      hash: json.hash,
      ext: json.ext,
      status: json.status,
      path: json.path,
      isMain: json.is_main,
      ordering: json.ordering
    } as AggregatorProfilePhotoModel);

    return model;
  }

  static empty(): AggregatorProfilePhotoModel {
    return new AggregatorProfilePhotoModel();
  }
}

export type AggregatorProfileSitemapReqPayload = {
  locationId: number;
  presentationCategoryId?: number;
  sort?: AggregatorProfileSortReqPayload;
  page?: number;
  order: 'asc' | 'desc';
  perPage?: number;
};

export type AggregatorProfileSitemapResPayload = {
  id: number;
  dateModified: Date;
  mainCategories: string[];
};

export type AggregatorProfileTrendingPagingReqPayload = {
  locationId: number;
  profileId: number;
  presentationCategoryId?: number;
};

export type AggregatorProfileTrendingPagingResPayload = {
  previousId?: number;
  nextId?: number;
};

export type AggregatorProfileWhatsNewDateResPayload = {
  isActive: boolean;
  isForced: boolean;
  eligible: boolean;
  last?: string;
  next: string;
  status?: ProfileWhatsNewDateEnum;
};

export type AggregatorProfileWhatsNewForceResPayload = {
  reason: AggregatorProfileWhatsNewForceReason;
  date: Date;
  profileId: string;
};

export enum AggregatorProfileWhatsNewForceReason {
  New = 'new',
  Updates = 'updates',
  Visiting = 'visiting'
}

export type AggregatorProfileWhatsNewPagingReqPayload = {
  locationId: number;
  profileId: number;
  isAvailableNow?: 0 | 1;
};

export type AggregatorProfileWhatsNewPagingResPayload = {
  previousId?: number;
  nextId?: number;
};

export type AggregatorSearchProfileReqPayload = {
  locationId?: number;
  mainCategory?: string;
  searchText?: string;
  isAvailableNow?: 0 | 1;
  page?: number;
  perPage?: number;
  latitude?: number;
  longitude?: number;
};

export type AggregatorProfileTrendingReqPayload = {
  locationId: number;
  presentationCategoryId?: number;
  page?: number;
  perPage?: number;
};

export type AggregatorProfileWhatsNewListReqPayload = {
  locationId: number;
  isAvailableNow?: number;
  page?: number;
  perPage?: number;
};

export enum ProfileWhatsNewDateEnum {
  Active = 'active',
  Forced = 'forced',
  Eligible = 'eligible',
  NotEligible = 'not eligible'
}

export interface QualifiedAdPremierServiceModel
  extends AggregatorPresentationCategoryModel,
    AggregatorLocationPresentationCategoryModel {}
