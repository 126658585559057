// Models
import {
  ProfileRevisionMetaModel,
  ProfileRevisionModel
} from '@eros-api/models/profile-revision.model';
import { ProfilePhotoModel } from '@eros-api/models/profile-photo.model';

export interface IProfileBaseModel {
  id: string;
  accountId: number;
  dateCreated: Date;
  isAuthenticated: boolean;
  isAvailableNow: boolean;
  isDeleted: boolean;
  isFast: boolean;
  isInventoryLocked: boolean;
  isMulti: boolean;
  personId: string;
  referenceId: number;
}

export class ProfileModel implements IProfileBaseModel {
  id!: string;
  accountId!: number;
  affiliation!: ProfileAffiliation | null;
  age!: number | null;
  alternativeSpecialty!: ProfileAlternativeSpecialty[] | null;
  availableFor!: ProfileAvailableForModel[] | null;
  availableTo!: ProfileAvailableTo[] | null;
  bioText!: string | null;
  bustSize!: number | null;
  cupSize!: ProfileCupSize | null;
  dateAvailable!: Date | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  dateReveal!: Date | null;
  disclaimer!: string | null;
  donations!: string | null;
  emails!: string[] | null;
  ethnicities!: ProfileEthnicity[] | null;
  eyeColor!: ProfileEyeColor | null;
  gender!: ProfileGender | null;
  hairColor!: ProfileHairColor | null;
  height!: number | null;
  hipSize!: number | null;
  inventoryId!: number | null;
  isAnimated!: boolean;
  isAuthenticated!: boolean;
  isAvailableNow!: boolean;
  isDeleted!: boolean;
  isFast!: boolean;
  isHidden!: boolean;
  isInventoryLocked!: boolean;
  isMulti!: boolean;
  isVip!: boolean;
  isVisiting!: boolean;
  locationCategories!: string[] | null;
  locationId!: number | null;
  locationTags!: string | null;
  magazineReference!: string | null;
  mainCategories!: ProfileMainCategory[] | null;
  movieReference!: string | null;
  offersList!: string | null;
  personId!: string;
  profileId: string | null;
  phoneNumbers!: ProfilePhoneNumberModel[] | null;
  photos!: ProfilePhotoModel[] | null;
  referenceId!: number;
  showname!: string | null;
  social!: ProfileSocialModel[] | null;
  specialtyCategories!: string[] | null;
  style!: ProfileStyle | null;
  tagline!: string | null;
  transOperation!: ProfileTransOperation | null;
  unit!: ProfileUnit | null;
  visitingText!: string | null;
  waistSize!: number | null;
  websites!: string[] | null;
  weight!: number | null;
  revision!: ProfileRevisionModel | null;
  revisionMeta!: ProfileRevisionMetaModel | null;
  mainPhoto!: any | null;
  youtubeUrl!: string | null;
  personIds!: string[] | null;
  isOnline!: boolean | null;
  url!: string | null;
  alwaysRequireId!: boolean | null;
  ageLabel: string;
  name: string;

  static fromJSON(json: any, isParseYoutubeUrl?: boolean): ProfileModel {
    const model = ProfileModel.empty();

    Object.assign(model, {
      id: json._id ?? Math.random(),
      accountId: json.account_id,
      name: json.name,
      affiliation: json.affiliation,
      age: json.age,
      alternativeSpecialty: json.alternative_specialty || null,
      availableFor: json.available_for
        ? json.available_for.map((aFor: ProfileAvailableForModel) =>
            ProfileAvailableForModel.fromJSON(aFor)
          )
        : null,
      availableTo: json.available_to || null,
      bioText: json.bio_text || null,
      bustSize: json.bust_size || null,
      cupSize: json.cup_size || null,
      personIds: json.person_ids,
      profileId: json.profile_id ?? null,
      dateAvailable: json.date_available ? new Date(json.date_available) : null,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      dateReveal: json.date_reveal ? new Date(json.date_reveal) : null,
      disclaimer: json.disclaimer || null,
      donations: json.donations || null,
      emails: json.emails || null,
      ethnicities: json.ethnicities || null,
      eyeColor: json.eye_color || null,
      gender: json.gender || null,
      hairColor: json.hair_color || null,
      height: json.height || null,
      hipSize: json.hip_size || null,
      inventoryId: json.inventory_id || null,
      isAnimated: Boolean(json.is_animated),
      isAuthenticated: Boolean(json.is_authenticated),
      isAvailableNow: Boolean(json.is_available_now),
      isDeleted: Boolean(json.is_deleted),
      isFast: Boolean(json.is_fast),
      isHidden: Boolean(json.is_hidden),
      isInventoryLocked: Boolean(json.is_inventory_locked),
      isMulti: Boolean(json.is_multi),
      isVip: Boolean(json.is_vip),
      isVisiting: Boolean(json.is_visiting),
      locationCategories: json.location_categories || null,
      locationId: json.location_id || null,
      locationTags: json.location_tags || null,
      magazineReference: json.magazine_reference || null,
      mainCategories: json.main_categories || null,
      movieReference: json.movie_reference || null,
      offersList: json.offers_list || null,
      personId: json.person_id,
      phoneNumbers: json.phone_numbers
        ? json.phone_numbers.map((num: ProfilePhoneNumberModel) =>
            ProfilePhoneNumberModel.fromJSON(num)
          )
        : null,
      photos: json.photos || null,
      mainPhoto: json.photos ? json.photos[0] : null,
      referenceId: json.reference_id,
      showname: json.showname || null,
      social: json.social
        ? json.social
            .filter(
              (social) =>
                ![
                  ProfileExcludedSocialType.Twitter,
                  ProfileExcludedSocialType.GooglePlus
                ].includes(social.type)
            )
            .map((social: ProfileSocialModel) =>
              ProfileSocialModel.fromJSON(social)
            )
        : null,
      specialtyCategories: json.specialty_categories || null,
      style: json.style || null,
      tagline: json.tagline || null,
      transOperation: json.trans_operation || null,
      unit: json.unit || null,
      visitingText: json.visiting_text || null,
      waistSize: json.waist_size || null,
      websites: json.websites || null,
      weight: json.weight || null,
      youtubeUrl: '',
      alwaysRequireId: !!json.always_require_id,
      revisionMeta: json.revision
        ? ProfileRevisionMetaModel.fromJSON(json.revision)
        : null
    } as ProfileModel);

    model.mainPhoto = model.photos ? model.photos[0] : null;

    if (model.age - Math.floor(model.age) === 0.5) {
      model.ageLabel = 'over ' + Math.floor(model.age);
    } else if (model.age) {
      model.ageLabel = model.age.toString();
    }

    return isParseYoutubeUrl ? parseYoutubeUrl(model) : model;
  }

  static toJSON(model: ProfileCreatePayload): Record<string, any> {
    return {
      _id: model.id,
      is_multi: model.isMulti,
      person_id: model.personId,
      account_id: model.accountId,
      is_fast: model.isFast
    };
  }

  static empty(): ProfileModel {
    return new ProfileModel();
  }
}

export class ProfileAvailableForModel {
  public type!: ProfileAvailableForType | null;
  public address!: number | null;
  public radius!: number | null;
  public latitude!: number | null;
  public longitude!: number | null;
  isEqual: (comp: any) => boolean;

  static fromJSON(json: any): ProfileAvailableForModel {
    const model = ProfileAvailableForModel.empty();

    Object.assign(model, {
      type: json.type,
      address: json.address,
      radius: json.radius,
      latitude: json.latitude,
      longitude: json.longitude,

      isEqual(comp: any): boolean {
        return (
          this.type === comp.type &&
          this.address === comp.address &&
          this.latitude === comp.latitude &&
          this.longitude === comp.longitude &&
          this.radius === comp.radius
        );
      }
    } as ProfileAvailableForModel);

    return model;
  }

  static toJson(model: ProfileAvailableForModel): Record<string, any> {
    return {
      type: model.type,
      address: model.address,
      radius: model.radius,
      latitude: model.latitude,
      longitude: model.longitude
    };
  }

  static empty(): ProfileAvailableForModel {
    return new ProfileAvailableForModel();
  }
}

export class ProfilePhoneNumberModel {
  number!: string;
  hasTextOnly!: boolean;
  hasWhatsapp!: boolean;
  hasViber!: boolean;

  static fromJSON(json: any): ProfilePhoneNumberModel {
    const model = ProfilePhoneNumberModel.empty();

    Object.assign(model, {
      number: json.number,
      hasTextOnly: Boolean(json.has_text_only),
      hasWhatsapp: Boolean(json.has_whatsapp),
      hasViber: Boolean(json.has_viber)
    } as ProfilePhoneNumberModel);

    return model;
  }

  static toJson(model: ProfilePhoneNumberModel): Record<string, any> {
    return {
      number: model.number,
      has_text_only: model.hasTextOnly,
      has_whatsapp: model.hasWhatsapp
    };
  }

  static empty(): ProfilePhoneNumberModel {
    return new ProfilePhoneNumberModel();
  }
}

export class ProfileSocialModel {
  type!: ProfileSocialType;
  handle!: string | null;

  isEqual(comp: any): boolean {
    return this.type === comp.type && this.handle === comp.handle;
  }

  isEmpty(): boolean {
    return !this.handle;
  }

  static fromJSON(json: any): ProfileSocialModel {
    const model = ProfileSocialModel.empty();

    Object.assign(model, {
      type: json.type,
      handle: json.handle
    } as ProfileSocialModel);

    return model;
  }

  static empty(): ProfileSocialModel {
    return new ProfileSocialModel();
  }
}

export interface CollectiveDashboardDocumentsModel {
  ids: number;
  authPhoto: number;
  unaltPhoto: number;
}

// Enums
export enum ProfileSocialType {
  Youtube = 'youtube',
  Facebook = 'facebook',
  Snapchat = 'snapchat',
  Vine = 'vine',
  Tumblr = 'tumblr',
  Periscope = 'periscope',
  Instagram = 'instagram',
  Tiktok = 'tiktok'
}

export enum ProfileExcludedSocialType {
  GooglePlus = 'google plus',
  Twitter = 'twitter'
}

export enum ProfileStyle {
  Default = 'default',
  Blue = 'blue',
  Purple = 'purple',
  Green = 'green',
  Pink = 'pink',
  Red = 'red',
  Brown = 'brown'
}

export enum ProfileAffiliation {
  Independent = 'independent',
  Agency = 'agency'
}

export enum ProfileTransOperation {
  Transsexual = 'transsexual',
  femaleToMaleTranssexual = 'female to male transsexual'
}

export enum ProfileUnit {
  Royal = 'royal',
  Metric = 'metric'
}

export enum ProfileAlternativeSpecialty {
  Null = 'null',
  Alternative = 'alternative',
  Hairstyle = 'hairstyle',
  Or = 'or',
  Lifestyle = 'lifestyle',
  Other = 'other',
  Body = 'body',
  Modifications = 'modifications',
  Piercings = 'piercings',
  Tattoos = 'tattoos'
}

export enum ProfileAvailableForType {
  Incall = 'incall',
  Outcall = 'outcall'
}

export enum ProfileAvailableTo {
  Men = 'men',
  Women = 'women',
  Couples = 'couples',
  Groups = 'groups',
  Trans = 'trans'
}

export enum ProfileEthnicity {
  Asian = 'asian',
  BlackEbony = 'black/ebony',
  Caucasian = 'caucasian',
  EastIndian = 'east indian',
  Exotic = 'exotic',
  LatinoHispani = 'latino/hispani',
  MiddleEastern = 'middle eastern',
  NativeAmerican = 'native american',
  PacificIslander = 'pacific islander'
}

export enum ProfileEyeColor {
  Black = 'black',
  Brown = 'brown',
  Blue = 'blue',
  Hazel = 'hazel',
  Green = 'green',
  Gray = 'gray'
}

export enum ProfileGender {
  Female = 'female',
  Transsexual = 'transsexual',
  Hermaphrodite = 'hermaphrodite',
  Male = 'male',
  Transvestite = 'transvestite',
  FemaleToMaleTranssexual = 'female to male transsexual'
}

export enum ProfileHairColor {
  Red = 'red',
  StrawberryBlonde = 'strawberry blonde',
  Blonde = 'blonde',
  Gray = 'gray',
  DirtyBlonde = 'dirty blonde',
  Brunette = 'brunette',
  DarkBrown = 'dark brown',
  Black = 'black',
  Brown = 'brown',
  Auburn = 'auburn',
  Bald = 'bald',
  Wild = 'wild'
}

export enum ProfileMainCategory {
  Escort = 'escort',
  Dancer = 'dancer',
  Massage = 'massage',
  Tantra = 'tantra',
  Fantasy = 'fantasy',
  TransEscort = 'trans escort'
}

export enum ProfileCupSize {
  kk = 'kk',
  m = 'm',
  mm = 'mm',
  mmm = 'mmm',
  n = 'n',
  nn = 'nn',
  nnn = 'nnn',
  o = 'o',
  oo = 'oo',
  ooo = 'ooo',
  p = 'p',
  pp = 'pp',
  ppp = 'ppp',
  a = 'a',
  aa = 'aa',
  aaa = 'aaa',
  b = 'b',
  bb = 'bb',
  bbb = 'bbb',
  c = 'c',
  cc = 'cc',
  ccc = 'ccc',
  d = 'd',
  dd = 'dd',
  ddd = 'ddd',
  e = 'e',
  ee = 'ee',
  eee = 'eee',
  f = 'f',
  ff = 'ff',
  fff = 'fff',
  g = 'g',
  gg = 'gg',
  ggg = 'ggg',
  h = 'h',
  hh = 'hh',
  hhh = 'hhh',
  i = 'i',
  ii = 'ii',
  iii = 'iii',
  j = 'j',
  jj = 'jj',
  jjj = 'jjj',
  k = 'k',
  kkk = 'kkk',
  l = 'l',
  ll = 'll',
  lll = 'lll'
}

export enum ProfileRevisionStatus {
  Pending = 'pending',
  Declined = 'declined',
  Approved = 'approved'
}

// API service Request and  Response types
export type ProfileCreatePayload = Pick<
  ProfileModel,
  'id' | 'isMulti' | 'personId' | 'accountId' | 'isFast'
>;

export type ProfileReqPayload = {
  accountId: number;
  personId?: string;
  showDeleted?: boolean;
};

export const parseYoutubeUrl = (profile: ProfileModel) => {
  const youtube = profile.social.find((soc) => soc.type === 'youtube');
  if (youtube && youtube.handle) {
    const url = youtube.handle;
    let match = new RegExp(/\?v=([^&?]+)/g).exec(url);
    if (match && match.length > 1) {
      profile.youtubeUrl = `https://www.youtube.com/embed/${match[1]}`;
    } else if ((match = new RegExp(/youtu\.be\/(.+)$/g).exec(url))) {
      profile.youtubeUrl = `https://www.youtube.com/embed/${match[1]}`;
    } else if (url.indexOf('embed') > -1) {
      profile.youtubeUrl = url;
    }
  }

  return profile;
};

export class DeclinedAPOSModel {
  accountId: number;
  profileId: string;
  referenceId: number;
  field: DeclinedAPOSFieldEnum;
  fieldValue: string;
  declineReason: string;
  revisionId: string;
  photoNumber?: number;
  id?: string;
  dateCreated?: Date | null;
  createdBy?: number;

  static fromJson(json: any): DeclinedAPOSModel {
    return {
      accountId: json.account_id,
      profileId: json.profile_id,
      referenceId: json.reference_id,
      field: json.field,
      fieldValue: json.field_value,
      declineReason: json.decline_reason,
      revisionId: json.revision_id,
      photoNumber: json.photo_number,
      id: json._id,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      createdBy: json.created_by
    };
  }
}

export interface DeclinedAPOSReqBody {
  account_id: number;
  profile_id: string;
  reference_id: number;
  field: DeclinedAPOSFieldEnum;
  field_value: string;
  decline_reason: string;
  revision_id: string;
  photo_number?: number;
  _id?: string;
  date_created?: string;
  created_by?: number;
}

export interface Patterns {
  min?: number;
  max?: number;
  pattern: string;
  maxCount: number;
}

export interface DeclinedAPOSMap {
  [key: string]: DeclinedAPOSModel;
}

export enum DeclinedAPOSFieldEnum {
  Tagline = 'tagline',
  VisitingText = 'visiting_text',
  Email = 'email',
  ShowName = 'showname',
  OffersList = 'offers_list',
  BioText = 'bio_text',
  Donations = 'donations',
  LocationTags = 'location_tags',
  Youtube = 'youtube',
  Facebook = 'facebook',
  Snapchat = 'snapchat',
  Vine = 'vine',
  Tumblr = 'tumblr',
  Periscope = 'periscope',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  Photo = 'photo'
}
