import { LeaveModel } from '@eros-api/models/location.model';
import { PremiumAdProductTypeResPayload } from '@eros-api/models/commerce';

export class DiscountModel {
  id!: number;
  name!: string;
  percentage!: number;
  systemMax!: number | null;
  accountMax!: number | null;
  startDate!: Date | string;
  endDate!: Date | string;
  promoCode!: string | null;
  discountReasonId!: number;
  rules!: DiscountRuleModel[] | null;
  createdBy!: number;
  updatedBy!: number | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  status!: DiscountStatus;
  totalUsed!: string;

  createdByAccount!: string;
  updatedByAccount!: string;
  reason!: string;

  static fromJSON(json: any): DiscountModel {
    const model = DiscountModel.empty();

    Object.assign(model, {
      id: json.id,
      name: json.name,
      percentage: json.percentage,
      systemMax: json.system_max ? json.system_max : null,
      accountMax: json.account_max ? json.account_max : null,
      startDate: new Date(json.start_date),
      endDate: new Date(json.end_date),
      promoCode: json.promo_code ? json.promo_code : null,
      discountReasonId: json.discount_reason_id,
      rules: json.rules
        ? json.rules.map((rule) => DiscountRuleModel.fromJSON(rule))
        : null,
      createdBy: json.created_by,
      updatedBy: json.updated_by || null,
      dateCreated: json.date_created,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      status: json.status,
      totalUsed: json.total_used,
      createdByAccount: null,
      updatedByAccount: null,
      reason: null
    } as DiscountModel);

    return model;
  }

  static toJSONCreateModel(
    model: DiscountCreateReqPayload
  ): Record<string, any> {
    return {
      name: model.name,
      percentage: model.percentage,
      system_max: +model.systemMax,
      account_max: +model.accountMax,
      start_date: model.startDate,
      end_date: model.endDate,
      promo_code: model.promoCode,
      discount_reason_id: model.discountReasonId,
      rules:
        model.rules &&
        model?.rules.map((rule) => DiscountRuleModel.toJSON(rule))
    };
  }

  static toJSONUpdateModel(
    model: DiscountUpdateReqPayload
  ): Record<string, any> {
    return {
      name: model.name,
      system_max: model.systemMax,
      account_max: model.accountMax,
      end_date: model.endDate
    };
  }

  static empty(): DiscountModel {
    return new DiscountModel();
  }
}

export class DiscountRuleModel {
  id!: number;
  type!: string;
  operator!: string;
  value!: string;
  discountId!: number;
  erosId!: number | null;
  market?: LeaveModel;
  productType?: PremiumAdProductTypeResPayload;

  static fromJSON(json: any): DiscountRuleModel {
    const model = DiscountRuleModel.empty();

    return Object.assign(model, {
      id: json.id,
      type: json.type,
      operator: json.operator,
      value: json.value,
      discountId: json.discount_id,
      erosId: json.eros_id || null
    } as DiscountRuleModel);
  }

  static toJSON(
    model: Pick<DiscountRuleModel, 'type' | 'operator' | 'value'>
  ): Record<string, any> {
    return model;
  }

  static empty(): DiscountRuleModel {
    return new DiscountRuleModel();
  }
}

export class DiscountCartApplyModel {
  id!: number;
  accountId!: number;
  profileId!: string | null;
  type!: DiscountType;
  premiumServiceName!: string | null;
  premiumServicePrincipalCategory!: string | null;
  premiumServiceSecondaryCategory!: string | null;
  isRenewal!: boolean | null;
  locationId!: number | null;

  static fromJSON(json: any): DiscountCartApplyModel {
    const model = DiscountCartApplyModel.empty();

    Object.assign(model, {
      id: json.id,
      accountId: json.account_id,
      profileId: json.profile_id ? json.profile_id : null,
      type: json.type,
      premiumServiceName: json.premium_service_name
        ? json.premium_service_name
        : null,
      premiumServicePrincipalCategory: json.premium_service_principal_category
        ? json.premium_service_principal_category
        : null,
      premiumServiceSecondaryCategory: json.premium_service_secondary_category
        ? json.premium_service_secondary_category
        : null,
      isRenewal: json.is_renewal ? json.is_renewal : null,
      locationId: json.location_id ? json.location_id : null
    } as DiscountCartApplyModel);

    return model;
  }

  static toJSON(model: DiscountApplyReqPayload) {
    return {
      id: model.id,
      account_id: model.accountId,
      profile_id: model.profileId,
      type: model.type,
      premium_service_name: model.premiumServiceName,
      premium_service_principal_category: model.premiumServicePrincipalCategory,
      premium_service_secondary_category: model.premiumServiceSecondaryCategory,
      is_renewal: model.isRenewal,
      location_id: model.locationId
    };
  }

  static empty(): DiscountCartApplyModel {
    return new DiscountCartApplyModel();
  }
}

export class DiscountReasonModel {
  id!: number;
  name!: string;
  shoppingBagActive!: boolean;
  discountToolActive!: boolean;

  static fromJSON(json: any): DiscountReasonModel {
    const model = DiscountReasonModel.empty();

    Object.assign(model, {
      id: json.id,
      name: json.name,
      shoppingBagActive: Boolean(json.shopping_bag_active),
      discountToolActive: Boolean(json.discount_tool_active)
    } as DiscountReasonModel);

    return model;
  }

  static empty(): DiscountReasonModel {
    return new DiscountReasonModel();
  }
}

export class DiscountCartApplyResPayload {
  discountId!: number;
  percentage!: number;
  items!: DiscountCartApplyModel[];

  static fromJSON(json: any): DiscountCartApplyResPayload {
    const model = DiscountCartApplyResPayload.empty();

    Object.assign(model, {
      discountId: json.discount_id,
      percentage: json.percentage,
      items: json.items
    } as DiscountCartApplyResPayload);

    return model;
  }

  static empty(): DiscountCartApplyResPayload {
    return new DiscountCartApplyResPayload();
  }
}

export type DiscountConfigModel = DiscountConfigModelResPayload;

export type DiscountCheckResPayload = {
  discount_id: number;
  percentage: number;
  account_id: number;
  profile_id?: string;
  type: DiscountType;
};

export type DiscountConfigModelResPayload = {
  rule_types: DiscountConfigRuleType[];
  promo_code: DiscountConfigModelPromoCode;
  service_types: string[];
};

export type DiscountConfigModelPromoCode = {
  min: number;
  max: number;
  valid_pattern: string;
};

export type DiscountConfigRuleType = {
  name: DiscountConfigRuleTypeName;
  eros_id: number;
  operators: string[];
  values?: string[];
};

export enum DiscountConfigRuleTypeName {
  Account = 'account',
  Market = 'market',
  AdType = 'ad type',
  PremierServiceType = 'premier service type',
  PremierServiceMainCategory = 'premier service main category',
  PremierServiceSubCategory = 'premier service sub category',
  AdCategory = 'ad category',
  NumberOfOnlineAds = 'number of online ads',
  OnlineAdsBulkExclusion = 'online ads bulk exclusion',
  TransactionType = 'transaction type',
  ProductType = 'product type',
  ServiceType = 'service type',
  FastAd = 'fast ad',
  Person = 'person',
  LastServiceExpirationDate = 'last service expiration date'
}

export type DiscountRuleByType = {
  id: string;
  text: string;
};

export enum DiscountConfigRuleTypeValues {
  Predefined = 'predefined',
  Freetext = 'freetext',
  Assistive = 'assistive'
}

export type DiscountCreateReqPayload = Pick<
  DiscountModel,
  | 'name'
  | 'percentage'
  | 'systemMax'
  | 'accountMax'
  | 'startDate'
  | 'endDate'
  | 'promoCode'
  | 'discountReasonId'
> & { rules: Array<Pick<DiscountRuleModel, 'type' | 'operator' | 'value'>> };

export type DiscountUpdateReqPayload = Pick<
  DiscountModel,
  'name' | 'systemMax' | 'accountMax' | 'endDate'
>;

export type DiscountApplyReqPayload = Pick<
  DiscountCartApplyModel,
  | 'id'
  | 'accountId'
  | 'profileId'
  | 'type'
  | 'premiumServiceName'
  | 'premiumServicePrincipalCategory'
  | 'premiumServiceSecondaryCategory'
  | 'isRenewal'
  | 'locationId'
>;

export enum DiscountType {
  PremiumAd = 'premium ad',
  Bump = 'bump',
  Renewal = 'renewal',
  PremiumService = 'premium service'
}

export enum DiscountStatus {
  Disabled = 'disabled',
  Expired = 'expired',
  Active = 'active',
  Pending = 'pending'
}

export class DiscountStatsModel {
  accountId!: number;
  countryId!: number;
  datePaymentReceived!: Date;
  locationId!: number;
  locationPath!: string;
  principalCategory!: string | null;
  secondaryCategory!: string | null;
  type!: number | null;
  referenceId!: number | null;

  mainCategories!: number[] | null;
  categories!: number[] | null;
  categoryIds!: number[] | null;

  static fromJSON(json: any): DiscountStatsModel {
    const model = DiscountStatsModel.empty();

    Object.assign(model, {
      accountId: json.account_id,
      countryId: json.country_id,
      datePaymentReceived: new Date(json.date_payment_received),
      locationId: json.location_id,
      locationPath: json.location_path,
      principalCategory: json.principal_category
        ? json.principal_category
        : null,
      secondaryCategory: json.secondary_category
        ? json.secondary_category
        : null,
      type: json.type ? json.type : null,
      referenceId: json.reference_id ? json.reference_id : null,
      mainCategories: json.main_categories ? json.main_categories : null,
      categories: json.categories ? json.categories : null,
      categoryIds: json.category_ids ? json.category_ids : null
    } as DiscountStatsModel);

    return model;
  }

  static empty(): DiscountStatsModel {
    return new DiscountStatsModel();
  }
}

export class DiscountUsageModel {
  took: number;
  timed_out: boolean;
  _shards: DiscountUsageShards;
  hits: DiscountUsageHits;
  aggregations: DiscountUsageAggregations;
}

export interface DiscountUsageShards {
  total: number;
  successful: number;
  failed: number;
}

export interface DiscountUsageHits {
  total: number;
  max_score: number;
  hits: any[];
}

export interface DiscountUsageAggregations {
  market: DiscountUsageMarket;
  secondary_category: DiscountUsageSecondaryCategory;
  principal_category: DiscountUsagePrincipalCategory;
  categories: DiscountUsageCategories;
  main_categories: DiscountUsageMainCategories;
  key: string;
  doc_count: number;
}

export enum DiscountUsageAggregationsEnum {
  Market = 'market',
  SecondaryCategory = 'secondary_category',
  PrincipalCategory = 'principal_category',
  Categories = 'categories',
  MainCategories = 'main_categories'
}

export interface DiscountUsageMarket {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: any[];
}

export interface DiscountUsageSecondaryCategory {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: any[];
}

export interface DiscountUsagePrincipalCategory {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: any[];
}

export interface DiscountUsageCategories {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: any[];
}

export interface DiscountUsageMainCategories {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: any[];
}
