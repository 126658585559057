import { AlertType } from '@app/libs/account/features/account-details/account-alerts/state/models/alerts-future.model';

export interface IActivity {
  id: string;
  accountId: number;
  createdBy: number;
  dateCreated: Date | null;
  dateModified: Date | null;
  erosId: number;
  modifiedBy: number | null;
  referenceId: number;
  referenceIds: number[];
  salesAccountId: number | null;
  text: string;
  type: ActivityType;
  escalation: { closing_note: string };
  formatted: string;
  performer: string;
}

export class ActivityModel implements IActivity {
  id!: string;
  accountId!: number;
  createdBy!: number;
  dateCreated!: Date | null;
  dateModified!: Date | null;
  erosId!: number;
  modifiedBy!: number | null;
  referenceId!: number;
  referenceIds!: number[];
  salesAccountId!: number | null;
  text!: string;
  type!: ActivityType;
  escalation!: { closing_note: string };
  performer!: string;
  formatted!: string;

  static fromJSON(json: any): ActivityModel {
    const model = ActivityModel.empty();

    Object.assign(model, {
      id: json.id,
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      erosId: json.eros_id ?? null,
      modifiedBy: json.modified_by ? json.modified_by : null,
      referenceId: json.reference_id,
      referenceIds: json.reference_ids,
      salesAccountId: json.sales_account_id ? json.sales_account_id : null,
      text: json.text,
      type: json.type,
      escalation: json.escalation
    } as ActivityModel);

    return model;
  }

  static empty(): ActivityModel {
    return new ActivityModel();
  }
}

export type ActivityListReqPayload = {
  accountId: number;
  limit?: number;
  type?: ActivityType[];
};

export type ActivityCreateReqPayload = {
  text: string;
  type: ActivityManualType;
};

export type ActivityListFlaggedHighlightReqPayload = {
  dateFrom: string;
  dateTo: string;
  tz?: string;
};

export interface IActivityAlert {
  id: string;
  text: string;
  accountId: number;
  personId: string | null;
  createdBy: number;
  dateCreated: Date;
  dateModified: Date | null;
  modifiedBy: number | null;
  ordering: number;
}

export class ActivityAlertModel implements IActivityAlert {
  id!: string;
  accountId!: number;
  createdBy!: number;
  dateCreated!: Date;
  dateModified!: Date | null;
  modifiedBy!: number | null;
  ordering!: number;
  personId!: string | null;
  text!: string;
  type: AlertType;

  static fromJSON(json: any): ActivityAlertModel {
    const model = ActivityAlertModel.empty();

    Object.assign(model, {
      id: json._id,
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      modifiedBy: json.modified_by ? json.modified_by : null,
      ordering: json.ordering,
      personId: json.person_id ?? null,
      text: json.text,
      type: json.type
    } as ActivityAlertModel);

    return model;
  }

  static empty(): ActivityAlertModel {
    return new ActivityAlertModel();
  }
}

export type ActivityAlertCreateReqPayload = {
  text: string;
  accountId: number;
  personId?: string;
  type: AlertType;
};

export type ActivityAlertUpdateReqPayload = {
  text: string;
  type?: AlertType;
  personId?: string;
};

export type ActivityAlertUpdateResPayload = {
  found: boolean;
  modified: boolean;
};

export type ActivityAlertDeleteResPayload = {
  found: boolean;
  modified: boolean;
};

export type ActivityAlertReorderResPayload = Array<{
  found: boolean;
  modified: boolean;
}>;

export interface IActivityConfig {
  activity_types: ActivityType[];
  manual_activity_types: ActivityManualType[];
  escalation_reasons: ActivityEscalationReason[];
  escalation_necessity: ActivityEscalationNecessity[];
  escalation_initiator: ActivityEscalationInitiator[];
  escalation_result: ActivityEscalationResult[];
  escalation_severity: ActivityEscalationSeverity[];
  escalation_status: ActivityEscalationStatus[];
  escalation_investigated: ActivityEscalationInvestigated[];
  escalation_callback_required: ActivityEscalationCallbackRequired[];
  escalation_callback_necessity: ActivityEscalationCallbackNecessity[];
  alert_text: {
    allowed_attributes: boolean;
    allowed_tags: [
      'b',
      'br',
      'em',
      'i',
      'ol',
      'li',
      'p',
      's',
      'span',
      'strike',
      'strong',
      'u',
      'ul'
    ];
  };
  activity_text: {
    allowed_attributes: boolean;
    allowed_tags: [
      'b',
      'br',
      'em',
      'i',
      'ol',
      'li',
      'p',
      's',
      'span',
      'strike',
      'strong',
      'u',
      'ul'
    ];
  };
}

export enum ActivityType {
  Csr = 'csr',
  Banners = 'banners',
  Data = 'data',
  System = 'system',
  Fraud = 'fraud',
  Services = 'services',
  Transactions = 'transactions',
  Account = 'account',
  Person = 'person',
  EmailChat = 'email/chat',
  Issue = 'issue',
  Escalation = 'escalations'
}

export type SelectActivityType = {
  name: string;
  value: string;
};

export type ActivityManualType =
  | ActivityType.Csr
  | ActivityType.Banners
  | ActivityType.Fraud
  | ActivityType.EmailChat
  | ActivityType.Issue;

export enum ActivityEscalationReason {
  undefined = 'undefined',
  AaIssues = 'aa issues',
  AopsPhotos = 'aops photos',
  AopsText = 'aops text',
  BaseAdIssues = 'base ad issues',
  ClientError = 'client error',
  Exceptions = 'exceptions',
  IdIssues = 'id issues',
  MistakesOnAd = 'mistakes on ad',
  Other = 'other',
  PaymentIssues = 'payment issues',
  PhotoIssues = 'photo issues',
  PolicyConfusion = 'policy confusion',
  PremierServices = 'premier services',
  PriceIncrease = 'price increase',
  AuthenticatedIssues = 'authenticated issues',
  VisitAdIssues = 'visit ad issues'
}

export enum ActivityEscalationNecessity {
  NotNecessary = 'not necessary',
  Necessary = 'necessary',
  ExtremelyNecessary = 'extremely necessary'
}

export enum ActivityEscalationInitiator {
  Csr = 'csr',
  Client = 'client'
}

export enum ActivityEscalationResult {
  ClientStillUnhappy = 'client still unhappy',
  ClientSatisfied = 'client satisfied',
  ClientHappy = 'client happy'
}

export enum ActivityEscalationSeverity {
  Minor = 'minor',
  Average = 'average',
  Severe = 'severe'
}

export enum ActivityEscalationStatus {
  New = 'new',
  Assigned = 'assigned',
  Closed = 'closed'
}

export enum ActivityEscalationInvestigated {
  Yes = 'yes',
  No = 'no',
  InProgress = 'in progress'
}

export enum ActivityEscalationCallbackRequired {
  Yes = 'yes',
  No = 'no'
}

export enum ActivityEscalationCallbackNecessity {
  NotNecessary = 'not necessary',
  Necessary = 'necessary',
  ExtremelyNecessary = 'extremely necessary'
}

export type SelectAlertType = {
  name: string;
  value: string;
};
