import { TicketQueue } from '@app/libs/ticket/features/tickets/state/utils';
import {
  AccountRoleEnum,
  OpsAccountModel
} from '@eros-api/models/account.model';

export class TicketModel {
  id!: string;
  accountId!: number;
  salesAccountId!: number | null;
  profileId!: string;
  referenceId!: number;
  revId!: string;
  locationId!: number;
  locationPath!: string;
  initiatedBy!: number;
  queue!: TicketQueue;
  priority!: string;
  dateCreated!: Date;
  dateModified!: Date | null;
  isSubmittedByDeposit!: boolean;
  isFast!: boolean | null;
  isOverlimited!: boolean | null;
  assignedBy!: number | null;
  geoPath!: TicketGeoPathModel[];
  takenBy!: ITicketTakenBy[];
  processedBy!: ITicketProcessedBy[];
  log!: ITicketLog[];
  escalation: ITicketEscalation | null;
  isTakable!: boolean;
  isStolen!: boolean;

  static fromJSON(json: any): TicketModel {
    const model = TicketModel.empty();

    Object.assign(model, {
      id: json._id,
      accountId: json.account_id,
      salesAccountId: json.sales_account_id ? json.sales_account_id : null,
      profileId: json.profile_id,
      referenceId: json.reference_id,
      revId: json.rev_id,
      locationId: json.location_id,
      locationPath: json.location_path,
      initiatedBy: json.initiated_by,
      queue: json.queue,
      priority: json.priority,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      isSubmittedByDeposit: json.is_submitted_by_deposit,
      isFast: json.is_fast ? json.is_fast : null,
      isOverlimited: json.is_overlimited ? json.is_overlimited : null,
      assignedBy: json.assigned_by ? json.assigned_by : null,
      geoPath: json.geo_path.map((geoPath: TicketGeoPathModel) =>
        TicketGeoPathModel.fromJSON(geoPath)
      ),
      takenBy: json.taken_by,
      processedBy: json.processed_by,
      log: json.log,
      escalation: json.escalation ?? null,
      isTakable: null,
      isStolen: json.is_stolen
    } as TicketModel);

    return model;
  }

  static empty(): TicketModel {
    return new TicketModel();
  }
}

export class TicketGeoPathModel {
  id!: number;
  type!: string;
  name!: string;
  parentId!: string;
  lft!: number;
  rgt!: number;
  iso!: string;
  latitude!: number;
  longitude!: number;
  keyword!: string | null;
  timezoneId!: number | null;
  timezone!: string | null;
  aliasOf!: number | null;
  isMapVisible!: number | null;

  static fromJSON(json: any): TicketGeoPathModel {
    const model = TicketGeoPathModel.empty();

    Object.assign(model, {
      id: json._id,
      type: json.type,
      name: json.name,
      parentId: json.parent_id,
      lft: json.lft,
      rgt: json.rgt,
      iso: json.iso,
      latitude: json.latitude,
      longitude: json.longitude,
      keyword: json.keyword ? json.keyword : null,
      timezoneId: json.timezone_id ? json.timezone_id : null,
      timezone: json.timezone ? json.timezone : null,
      aliasOf: json.alias_of ? json.alias_of : null,
      isMapVisible: json.is_map_visible ? json.is_map_visible : null
    } as TicketGeoPathModel);

    return model;
  }

  static empty(): TicketGeoPathModel {
    return new TicketGeoPathModel();
  }
}

export interface ITicketTakenBy {
  date_processed: string;
  role: AccountRoleEnum;
  processed_by: number;
  accountReferenceName?: string;
}

export interface ITicketProcessedBy {
  date_processed: string;
  role: string;
  processed_by: number;
  event: string;
  account: OpsAccountModel;
}

export interface ITicketLog {
  date_created: Date;
  role: string;
  processed_by: number;
  event: string;
  is_stolen: boolean;
  assigned_to_id: string | null;
  assigned_to_role: string | null;
  processedByAccount: OpsAccountModel;
  assignedToAccount: OpsAccountModel;
}

export interface ITicketEscalation {
  date_created: Date;
  date_resolved: Date;
  type: TicketEscalationType;
  created_by: number;
  result: string | null;
  resolved_by: string | null;
  notes: ITicketEscalationNote[];
}

export interface ITicketEscalationNote {
  note: string;
  date_created: Date;
  processed_by: number;
  role: string;
  processedByAccount: OpsAccountModel;
}

export interface ITicketConfig {
  priority_types: TicketPriorityType[];
  escalation_types: TicketEscalationType[];
  escalation_resolve_types: TicketEscalationResolveType[];
}

export enum TicketPriorityType {
  Priority1 = 'priority 1',
  Priority2 = 'priority 2',
  Priority3 = 'priority 3',
  Priority01 = 'priority 01',
  Priority02 = 'priority 02'
}

export enum TicketEscalationType {
  Photos = 'photos',
  Documents = 'documents',
  Text = 'text',
  Other = 'other'
}

export enum TicketEscalationResolveType {
  Approved = 'approved',
  Declined = 'declined'
}

export type TicketBulkResPayload = {
  profileId: string;
  priority: string;
};

export type TicketCountByProfileIdReqPayload = {
  accountId: number;
  profileId: string;
  priorities: TicketPriorityType[];
};

export type TicketEscalateReqPayload = {
  type: TicketEscalationType;
  note?: string;
};

export type TicketResolveReqPayload = {
  result: TicketResolveStatus;
  note?: string;
};

export enum TicketResolveStatus {
  Approved = 'approved',
  Declined = 'declined'
}

export enum PriorityQueues {
  'Priority01' = 'priority 01',
  'Priority02' = 'priority 02',
  'Priority1' = 'priority 1',
  'Priority2' = 'priority 2',
  'Priority3' = 'priority 3'
}

export type SelectTicketByQueuePriority = {
  name: string;
  value: string;
};

export type SelectOwnerAccountsData = {
  id: string;
  value: string;
};

export interface DailySolved {
  issue: number;
  process: number;
  review: number;
}

export type TicketCountByQueue = Record<
  Exclude<TicketQueue, TicketQueue.Deleted>,
  number
>;

export interface CollectiveTicketsModel {
  process: ProcessReviewModel;
  review: ProcessReviewModel;
  issue: ProcessReviewModel;
}

export interface ProcessReviewModel {
  priority3: number;
  priority1: number;
  priority2: number;
}
