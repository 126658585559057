import { Currency } from '@eros-api/models/commerce';
import { environment } from '@env/environment';

export interface IMediaConfig {
  aa_version: number;
  calling_code: {
    max: number;
    min: number;
    valid_pattern: string;
  };
  currencies: Currency[];
  email: {
    valid_pattern: string;
  };
  ipv4_regex: string;
  object_id: {
    valid_pattern: string;
  };
  phone: {
    max: number;
    min: number;
    valid_pattern: string;
  };
  shredder_account_inactivity_threshold: number;
  shredder_account_no_service_threshold: number;
  supportedLangs: MediaConfigSupportedLang[];
  url: {
    valid_pattern: string;
  };
  week_days: MediaWeekday[];
  banner_image_mimetypes: MediaBannerImageMimetype[];
  document_mimetypes: MediaDocumentMimetype[];
  document_purposes: MediaDocumentPurpose[];
  document_test: number;
  fieldname: string;
  file_size: {
    document: string;
    image: string;
  };
  image_max_size: string;
  image_quality: string;
  profile_image_height: {
    max: string;
    min: string;
  };
  profile_image_mimetypes: MediaProfileImageMimetype[];
  profile_image_width: {
    max: string;
    min: string;
  };
  profile_landscape_image_height: {
    min: string;
  };
  profile_landscape_image_width: {
    max: string;
    min: string;
  };
  profile_portrait_image_height: {
    max: string;
    min: string;
  };
  profile_portrait_image_width: {
    min: string;
  };
  promo_banner_image_mimetypes: MediaPromoBannerImageMimetype[];
  purposes_to_resize: MediaResizePurpose[];
  purposes: MediaPurpose[];
  resize_thumbs: MediaResizeThumb[];
  thumb_sizes: {
    orig: {
      _child: IMediaThumbSizeOrigChild[];
    };
  };
  timeout: {
    document: string;
    image: string;
  };
  upload_dir: string;
}

export class MediaDocumentModel {
  id!: string;
  accountId!: number;
  createdBy!: number;
  purpose!: MediaDocumentPurpose;
  filename!: string;
  hash!: string;
  ext!: string;
  path!: string;
  dateCreated!: Date;

  mediaUrl!: string;

  static fromJSON(json: any): MediaDocumentModel {
    const model = MediaDocumentModel.empty();

    Object.assign(model, {
      id: json._id,
      accountId: json.account_id,
      createdBy: json.created_by,
      purpose: json.purpose,
      filename: json.filename,
      hash: json.hash,
      ext: json.ext,
      path: json.path,
      dateCreated: new Date(json.date_created),
      mediaUrl: environment.contentServerUrl + '/' + json.path
    } as MediaDocumentModel);

    return model;
  }

  static empty(): MediaDocumentModel {
    return new MediaDocumentModel();
  }
}

export class MediaImageModel {
  imageId!: string;
  status!: string;
  type!: string;
  thumbName!: ThumbNameEnum;
  filename!: string;
  hash!: string;
  path!: string;
  width!: number;
  height!: number;
  size!: number;
  isPortrait!: boolean;
  dateCreated!: Date;
  mediaUrl!: string;

  static fromJSON(json: any): MediaImageModel {
    const model = MediaImageModel.empty();

    Object.assign(model, {
      imageId: json.image_id,
      status: json.status,
      type: json.type,
      thumbName: json.thumb_name,
      filename: json.filename,
      hash: json.hash,
      ext: json.ext,
      path: json.path,
      width: json.width,
      height: json.height,
      size: json.size,
      isPortrait: json.is_portrait,
      dateCreated: new Date(json.date_created),
      mediaUrl: environment.contentServerUrl + '/' + json.path
    } as MediaImageModel);

    return model;
  }

  static empty(): MediaImageModel {
    return new MediaImageModel();
  }
}

export class MediaPromoBannerModel {
  id!: string;
  accountId!: number;
  status!: string;
  filename!: string;
  hash!: string;
  path!: string;
  width!: number;
  height!: number;
  size!: number;
  isPortrait!: boolean;
  dateCreated!: Date;

  static fromJSON(json: any): MediaPromoBannerModel {
    const model = MediaPromoBannerModel.empty();

    Object.assign(model, {
      id: json.image_id,
      accountId: json.account_id,
      status: json.status,
      filename: json.filename,
      hash: json.hash,
      ext: json.ext,
      path: json.path,
      width: json.width,
      height: json.height,
      size: json.size,
      isPortrait: json.is_portrait,
      dateCreated: new Date(json.date_created)
    } as MediaPromoBannerModel);

    return model;
  }

  static empty(): MediaPromoBannerModel {
    return new MediaPromoBannerModel();
  }
}

export enum MediaConfigSupportedLang {
  En = 'en',
  It = 'it'
}

export enum MediaWeekday {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

export enum MediaBannerImageMimetype {
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Gif = 'image/gif'
}

export enum MediaDocumentMimetype {
  Jpeg = 'image/jpeg',
  VideoQuicktime = 'video/quicktime',
  VideoMp4 = 'video/mp4'
}

export enum MediaDocumentPurpose {
  Verification = 'verification'
}

export enum MediaProfileImageMimetype {
  Jpeg = 'image/jpeg',
  Png = 'image/png'
}

export enum MediaPromoBannerImageMimetype {
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Gif = 'image/gif'
}

export enum MediaResizePurpose {
  Profile = 'profile'
}

export enum MediaPurpose {
  Profile = 'profile',
  banner = 'banner',
  PromoBanner = 'promo_banner'
}

export enum MediaResizeThumb {
  Thumb558 = 'thumb_558'
}

export enum ThumbNameEnum {
  Original = 'original',
  Thumb265 = 'thumb_265'
}

export interface IMediaThumbSizeOrigChild {
  name: string;
  action: string;
  watermark: string;
  width: number;
  height: number;
  _child?: IMediaThumbSizeOrigChild[];
}

export interface PromoBannerMediaResModel {
  _id: string;
  account_id: number;
  status: string;
  filename: string;
  hash: string;
  ext: string;
  path: string;
  width: number;
  height: number;
  is_portrait: boolean;
  size: number;
  date_created: Date;
}

export class MediaVideoModel {
  id: string;
  accountId: number;
  status: string;
  purpose: string;
  originalname: string;
  filename: string;
  hash: string;
  ext: string;
  path: string;
  height: number;
  width: number;
  mimetype: string;
  sourceMeta: SourceMeta;
  dateCreated: string;
  progress: string;
  duration: string;
  coconutJobId: string;
  thumbs: Thumb[];
  videos: Video[];
  mediaUrl: string;

  static fromJSON(json: any): MediaVideoModel {
    return {
      id: json._id,
      accountId: json.account_id,
      status: json.status,
      purpose: json.purpose,
      originalname: json.originalname,
      filename: json.filename,
      hash: json.hash,
      ext: json.ext,
      path: json.path,
      height: json.height,
      width: json.width,
      mimetype: json.mimetype,
      mediaUrl: environment.contentServerUrl + '/' + json.videos[0].path,
      sourceMeta: {
        height: json.source_meta.height,
        width: json.source_meta.width,
        fileType: json.source_meta.file_type,
        mimetype: json.source_meta.mimetype,
        duration: json.source_meta.duration,
        size: json.source_meta.size,
        videoBitRate: json.source_meta.video_bit_rate,
        audioBitRate: json.source_meta.audio_bit_rate
      },
      dateCreated: json.date_created,
      progress: json.progress,
      duration: json.duration,
      coconutJobId: json.coconut_job_id,
      thumbs: json.thumbs.map((item: any) => ({
        id: item._id,
        ext: item.ext,
        path: environment.contentServerUrl + '/' + item.path,
        type: item.type,
        number: item.number
      })),
      videos: json.videos.map((item: any) => ({
        id: item._id,
        ext: item.ext,
        path: item.path,
        type: item.type
      }))
    };
  }
}

export interface SourceMeta {
  height: number;
  width: number;
  fileType: string;
  mimetype: string;
  duration: string;
  size: number;
  videoBitRate: string;
  audioBitRate: any;
}

export interface Thumb {
  id: string;
  ext: string;
  path: string;
  type: string;
  number: number;
}

export interface Video {
  id: string;
  ext: string;
  path: string;
  type: string;
}
