import { formatReferenceName } from '@app/libs/account/utils/format-reference-name';
import {
  CommerceOptionsCreditCard,
  CommerceOptionsPaymentGroup
} from '@eros-api/models/commerce';
import { Currency } from './commerce/currency.enum';

export class AccountModel {
  activationHash!: string | null;
  agreementVersion!: number | null;
  contactName!: string | null;
  currency!: Currency;
  dateAgreementReset!: Date | null;
  dateCreated!: Date | null;
  dateLastDisabled!: Date | null;
  dateLastLogin!: Date | null;
  dateModified!: Date | null;
  dateVerified!: Date | null;
  disableReason!: string | null;
  disableType!: AccountDisableType | null;
  email!: string;
  erosAccountId!: number | null;
  erosRoles!: AccountErosRole[] | null;
  erosUserId!: number | null;
  firstName!: string | null;
  flags!: AccountFlag[] | null;
  id!: number;
  lastName!: string | null;
  middleName!: string | null;
  nameSuffix!: string | null;
  oldPassword!: string | null;
  password!: string;
  passwordRecoveryHash!: string | null;
  pendingEmail!: string | null;
  phoneNumber!: string | null;
  portfolioId!: number | null;
  preferences!: AccountPreference[] | null;
  requirements!: AccountRequirement[] | null;
  role!: AccountRoleEnum;
  salesAccountId!: number | null;
  secretPin!: string | null;
  securityAnswer!: string | null;
  securityQuestion!: string | null;
  status!: AccountStatus;
  username!: string;
  formatLegalName?: string;
  formatReferenceName?: string;
  tfaType: VerificationOptionEnum;
  blockedPaymentGroups?: (
    | CommerceOptionsPaymentGroup
    | CommerceOptionsCreditCard
  )[];

  static fromJSON(json: any): AccountModel {
    const model = AccountModel.empty();

    Object.assign(model, {
      activationHash: json.activation_hash ? json.activation_hash : null,
      agreementVersion: json.aa_version ? json.aa_version : null,
      contactName: json.contact_name ? json.contact_name : null,
      currency: json.currency,
      dateAgreementReset: json.date_aa_reset ? json.date_aa_reset : null,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateLastDisabled: json.date_last_disabled
        ? new Date(json.date_last_disabled)
        : null,
      dateLastLogin: json.date_last_login
        ? new Date(json.date_last_login)
        : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      dateVerified: json.date_verified ? new Date(json.date_verified) : null,
      disableReason: json.disable_reason ? json.disable_reason : null,
      disableType: json.disable_type ? json.disable_type : null,
      email: json.email,
      erosAccountId: json.eros_account_id ? json.eros_account_id : null,
      erosRoles: json.eros_roles ? json.eros_roles : null,
      erosUserId: json.eros_user_id ? json.eros_user_id : null,
      firstName: json.first_name ? json.first_name : null,
      flags: json.flags ? json.flags : null,
      id: json.id,
      lastName: json.last_name ? json.last_name : null,
      middleName: json.middle_name ? json.middle_name : null,
      nameSuffix: json.name_suffix ? json.name_suffix : null,
      oldPassword: json.old_password ? json.old_password : null,
      password: json.password,
      passwordRecoveryHash: json.password_recovery_hash
        ? json.password_recovery_hash
        : null,
      pendingEmail: json.pending_email ? json.pending_email : null,
      permissions: json.permissions ? json.permissions : null,
      phoneNumber: json.phone_number ? json.phone_number : null,
      portfolioId: json.portfolio_id ? json.portfolio_id : null,
      preferences: json.preferences ? json.preferences : null,
      requirements: json.requirements
        ? AccountModel.setRequirementsfromJSON(json)
        : null,
      role: json.role,
      salesAccountId: json.sales_account_id ? json.sales_account_id : null,
      secretPin: json.secret_pin ? json.secret_pin : null,
      securityAnswer: json.security_answer ? json.security_answer : null,
      securityQuestion: json.security_question ? json.security_question : null,
      status: json.status,
      username: json.username,
      tfaType: json.tfa_type
    } as AccountModel);

    return model;
  }

  static toJSONUpdateModel(
    model: AccountUpdateReqPayload
  ): Record<string, any> {
    return {
      email: model.email,
      phone_number: model.phoneNumber,
      contact_name: model.contactName,
      security_question: model.securityQuestion,
      security_answer: model.securityAnswer,
      aa_version: model.agreementVersion,
      first_name: model.firstName,
      last_name: model.lastName,
      middle_name: model.middleName,
      name_suffix: model.nameSuffix,
      secret_pin: model.secretPin,
      currency: model.currency
    };
  }

  static empty(): AccountModel {
    return new AccountModel();
  }

  private static setRequirementsfromJSON(json: any): string[] {
    const requirements = json.requirements || [];

    return requirements.includes('id') ? requirements : ['id', ...requirements];
  }
}

export type AccountUpdateReqPayload = Pick<
  AccountModel,
  | 'email'
  | 'phoneNumber'
  | 'contactName'
  | 'securityQuestion'
  | 'securityAnswer'
  | 'agreementVersion'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'nameSuffix'
  | 'secretPin'
  | 'currency'
>;

export class OpsAccountModel {
  email!: string;
  firstName!: string | null;
  id!: number;
  lastName!: string | null;
  role!: AccountRoleEnum;
  status!: AccountStatus;
  username!: string;
  formatReferenceName?: string;
  index?: number;

  static fromJSON(json: any): OpsAccountModel {
    const model = OpsAccountModel.empty();

    Object.assign(model, {
      email: json.email,
      firstName: json.first_name || null,
      flags: json.flags,
      id: json.id,
      lastName: json.last_name || null,
      role: json.role,
      status: json.status,
      username: json.username
    } as OpsAccountModel);

    model.formatReferenceName = formatReferenceName(model);

    return model;
  }

  static empty(): OpsAccountModel {
    return new OpsAccountModel();
  }
}

export interface IAccountFollower {
  id: number;
  opsAccountId: number;
  accountId: number;
  dateCreated: Date;
}

export class AccountFollowerModel implements IAccountFollower {
  id!: number;
  opsAccountId!: number;
  accountId!: number;
  dateCreated!: Date;
  opsAccount?: OpsAccountModel;
  reason?: string;

  static fromJSON(json: any): AccountFollowerModel {
    const model = AccountFollowerModel.empty();

    Object.assign(model, {
      id: json.id,
      opsAccountId: json.ops_account_id,
      accountId: json.account_id,
      dateCreated: new Date(json.date_created),
      reason: json.reason ?? ''
    } as AccountFollowerModel);

    return model;
  }

  static empty(): AccountFollowerModel {
    return new AccountFollowerModel();
  }
}

export interface IAccountFollowerResModel {
  id: number;
  ops_account_id: number;
  account_id: number;
  date_created: Date;
  reason?: string | null;
  accountContactName: null;
}

export class FollowedAccountModel implements IAccountFollower {
  id!: number;
  opsAccountId!: number;
  accountId!: number;
  dateCreated!: Date;
  reason!: string | null;
  accountContactName!: string | null;

  static fromJSON(json: IAccountFollowerResModel): FollowedAccountModel {
    const model = FollowedAccountModel.empty();

    Object.assign(model, {
      id: json.id,
      opsAccountId: json.ops_account_id,
      accountId: json.account_id,
      dateCreated: new Date(json.date_created),
      reason: json.reason ? json.reason : null,
      accountContactName: null
    } as FollowedAccountModel);

    return model;
  }

  static empty(): FollowedAccountModel {
    return new FollowedAccountModel();
  }
}

export class AccountNotificationModel {
  id!: number;
  opsAccountId!: number;
  accountId!: number;
  dateCreated!: Date;
  type!: string;
  message!: string;
  isSeen!: boolean;
  dateSeen!: Date | null;
  value!: string;
  reason: string | null;

  static fromJSON(json: any): AccountNotificationModel {
    const model = AccountNotificationModel.empty();

    Object.assign(model, {
      id: json.id,
      opsAccountId: json.ops_account_id,
      accountId: json.account_id,
      dateCreated: new Date(json.date_created),
      type: json.type,
      message: json.message,
      isSeen: json.is_seen,
      dateSeen: json.date_seen ? new Date(json.date_seen) : null,
      value: json.value,
      reason: json.reason ? json.reason : null
    } as AccountNotificationModel);

    return model;
  }

  static empty(): AccountNotificationModel {
    return new AccountNotificationModel();
  }
}

export interface INewCustomerByEmail {
  id: number;
  username: string;
  password: string | null;
  role: AccountRoleEnum;
  status: AccountStatus;
  phoneNumber: string;
  email: string;
  pendingEmail: string | null;
  activationHash: string | null;
  passwordRecoveryHash: string | null;
  salesAccountId: number | null;
  dateCreated: Date | null;
  dateModified: Date | null;
  dateVerified: Date | null;
  dateLastLogin: Date | null;
  dateLastDisabled: Date | null;
  dateAaReset: Date | null;
  aaVersion: number | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  nameSuffix: string | null;
  securityQuestion: string | null;
  securityAnswer: string | null;
  secretPin: string | null;
  portfolioId: string | null;
  disableReason: string | null;
  disableType: AccountDisableType | null;
  oldPassword: string | null;
  erosUserId: string | null;
  erosAccountId: string | null;
  contactName: string | null;
  requirements: AccountRequirement | null;
  flags: AccountFlag | null;
  preferences: AccountPreference | null;
  erosRoles: AccountErosRole | null;
  currency: Currency | null;
  _email: string | null;
}

export class AccountNewCustomerModel implements INewCustomerByEmail {
  id!: number;
  username!: string;
  password!: string | null;
  role!: AccountRoleEnum;
  status!: AccountStatus;
  phoneNumber!: string;
  email!: string;
  pendingEmail!: string | null;
  activationHash!: string | null;
  passwordRecoveryHash!: string | null;
  salesAccountId!: number | null;
  dateCreated!: Date | null;
  dateModified!: Date | null;
  dateVerified!: Date | null;
  dateLastLogin!: Date | null;
  dateLastDisabled!: Date | null;
  dateAaReset!: Date | null;
  aaVersion!: number | null;
  firstName!: string | null;
  lastName!: string | null;
  middleName!: string | null;
  nameSuffix!: string | null;
  securityQuestion!: string | null;
  securityAnswer!: string | null;
  secretPin!: string | null;
  portfolioId!: string | null;
  disableReason!: string | null;
  disableType!: AccountDisableType | null;
  oldPassword!: string | null;
  erosUserId!: string | null;
  erosAccountId!: string | null;
  contactName!: string | null;
  requirements!: AccountRequirement | null;
  flags!: AccountFlag | null;
  preferences!: AccountPreference | null;
  erosRoles!: AccountErosRole | null;
  currency!: Currency | null;
  _email!: string | null;

  static fromJSON(json: any): AccountNewCustomerModel {
    const model = AccountNewCustomerModel.empty();

    Object.assign(model, {
      id: json.id,
      username: json.username,
      password: json.password || null,
      role: json.role,
      status: json.status,
      phoneNumber: json.phone_number,
      email: json.email,
      pendingEmail: json.pending_email || null,
      activationHash: json.activation_hash || null,
      passwordRecoveryHash: json.password_recovery_hash || null,
      salesAccountId: json.sales_account_id || null,
      dateCreated: new Date(json.date_created) || null,
      dateModified: new Date(json.date_modified) || null,
      dateVerified: new Date(json.date_verified) || null,
      dateLastLogin: new Date(json.date_last_login) || null,
      dateLastDisabled: new Date(json.date_last_disabled) || null,
      dateAaReset: new Date(json.date_aa_reset) || null,
      aaVersion: json.aa_version || null,
      firstName: json.first_name || null,
      lastName: json.last_name || null,
      middleName: json.middle_name || null,
      nameSuffix: json.name_suffix || null,
      securityQuestion: json.security_question || null,
      securityAnswer: json.security_answer || null,
      secretPin: json.secret_pin || null,
      portfolioId: json.portfolio_id || null,
      disableReason: json.disable_reason || null,
      disableType: json.disable_type || null,
      oldPassword: json.old_password || null,
      erosUserId: json.eros_user_id || null,
      erosAccountId: json.eros_account_id || null,
      contactName: json.contact_name || null,
      requirements: json.requirements || null,
      flags: json.flags || null,
      preferences: json.preferences || null,
      erosRoles: json.eros_roles || null,
      currency: json.currency || null,
      _email: json._email || null
    } as AccountNewCustomerModel);

    return model;
  }

  static empty(): AccountNewCustomerModel {
    return new AccountNewCustomerModel();
  }
}

export class AccountCustomerByEmailModel implements INewCustomerByEmail {
  id!: number;
  username!: string;
  password!: string | null;
  role!: AccountRoleEnum;
  status!: AccountStatus;
  phoneNumber!: string;
  email!: string;
  pendingEmail!: string | null;
  activationHash!: string | null;
  passwordRecoveryHash!: string | null;
  salesAccountId!: number | null;
  dateCreated!: Date | null;
  dateModified!: Date | null;
  dateVerified!: Date | null;
  dateLastLogin!: Date | null;
  dateLastDisabled!: Date | null;
  dateAaReset!: Date | null;
  aaVersion!: number | null;
  firstName!: string | null;
  lastName!: string | null;
  middleName!: string | null;
  nameSuffix!: string | null;
  securityQuestion!: string | null;
  securityAnswer!: string | null;
  secretPin!: string | null;
  portfolioId!: string | null;
  disableReason!: string | null;
  disableType!: AccountDisableType | null;
  oldPassword!: string | null;
  erosUserId!: string | null;
  erosAccountId!: string | null;
  contactName!: string | null;
  requirements!: AccountRequirement | null;
  flags!: AccountFlag | null;
  preferences!: AccountPreference | null;
  erosRoles!: AccountErosRole | null;
  currency!: Currency | null;
  _email!: string | null;

  static fromJSON(json: any): AccountCustomerByEmailModel {
    const model = AccountCustomerByEmailModel.empty();

    Object.assign(model, {
      id: json.id,
      username: json.username,
      password: json.password || null,
      role: json.role,
      status: json.status,
      phoneNumber: json.phone_number,
      email: json.email,
      pendingEmail: json.pending_email || null,
      activationHash: json.activation_hash || null,
      passwordRecoveryHash: json.password_recovery_hash || null,
      salesAccountId: json.sales_account_id || null,
      dateCreated: new Date(json.date_created) || null,
      dateModified: new Date(json.date_modified) || null,
      dateVerified: new Date(json.date_verified) || null,
      dateLastLogin: new Date(json.date_last_login) || null,
      dateLastDisabled: new Date(json.date_last_disabled) || null,
      dateAaReset: new Date(json.date_aa_reset) || null,
      aaVersion: json.aa_version || null,
      firstName: json.first_name || null,
      lastName: json.last_name || null,
      middleName: json.middle_name || null,
      nameSuffix: json.name_suffix || null,
      securityQuestion: json.security_question || null,
      securityAnswer: json.security_answer || null,
      secretPin: json.secret_pin || null,
      portfolioId: json.portfolio_id || null,
      disableReason: json.disable_reason || null,
      disableType: json.disable_type || null,
      oldPassword: json.old_password || null,
      erosUserId: json.eros_user_id || null,
      erosAccountId: json.eros_account_id || null,
      contactName: json.contact_name || null,
      requirements: json.requirements || null,
      flags: json.flags || null,
      preferences: json.preferences || null,
      erosRoles: json.eros_roles || null,
      currency: json.currency || null,
      _email: json._email || null
    } as AccountCustomerByEmailModel);

    return model;
  }

  static empty(): AccountCustomerByEmailModel {
    return new AccountCustomerByEmailModel();
  }
}

export class AccountTokeListModel {
  id!: number;
  accountId!: number;
  tokenHash!: string;
  deviceHash!: string;
  deviceName!: string;
  deviceStatus!: AccountTokenDeviceStatus;
  deviceVerificationCode!: string;
  tokenDateCreated!: Date;
  deviceDateCreated!: Date;
  tokenDateLastActivity!: Date;
  deviceDateExpiration!: Date;

  static fromJSON(json: any): AccountTokeListModel {
    const model = AccountTokeListModel.empty();

    Object.assign(model, {
      id: json.id,
      accountId: json.account_id,
      tokenHash: json.token_hash,
      deviceHash: json.device_hash,
      deviceName: json.device_name,
      deviceStatus: json.device_status,
      deviceVerificationCode: json.device_verification_code,
      tokenDateCreated: new Date(json.token_date_created),
      deviceDateCreated: new Date(json.device_date_created),
      tokenDateLastActivity: new Date(json.token_date_last_activity),
      deviceDateExpiration: new Date(json.device_date_expiration)
    } as AccountTokeListModel);

    return model;
  }

  static empty(): AccountTokeListModel {
    return new AccountTokeListModel();
  }
}

export interface IAccountVerify {
  id: number;
  role: AccountRoleEnum;
  status: AccountStatus;
  phoneNumber: string;
  email: string;
  username: string;
  salesAccountId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  contactName: string;
  aaVersion: string;
  currency: Currency;
  permissions: IAccountPermission[];
  token: string;
}

export class AccountVerifyModel implements IAccountVerify {
  id!: number;
  role!: AccountRoleEnum;
  status!: AccountStatus;
  phoneNumber!: string;
  email!: string;
  username!: string;
  salesAccountId!: number;
  firstName!: string;
  lastName!: string;
  middleName!: string;
  contactName!: string;
  aaVersion!: string;
  currency!: Currency;
  permissions!: IAccountPermission[];
  token!: string;

  static fromJSON(json: any): AccountVerifyModel {
    const model = AccountVerifyModel.empty();

    Object.assign(model, {
      id: json.number,
      role: json.role,
      status: json.status,
      phoneNumber: json.phone_number,
      email: json.email,
      username: json.username,
      salesAccountId: json.sales_account_id,
      firstName: json.first_name,
      lastName: json.last_name,
      middleName: json.middle_name,
      contactName: json.contact_name,
      aaVersion: json.aa_version,
      currency: json.currency,
      permissions: json.permissions,
      token: json.token
    } as AccountVerifyModel);

    return model;
  }

  static empty(): AccountVerifyModel {
    return new AccountVerifyModel();
  }
}

export class AccountVerifyEmailModel implements IAccountVerify {
  id!: number;
  role!: AccountRoleEnum;
  status!: AccountStatus;
  phoneNumber!: string;
  email!: string;
  username!: string;
  salesAccountId!: number;
  firstName!: string;
  lastName!: string;
  middleName!: string;
  contactName!: string;
  aaVersion!: string;
  currency!: Currency;
  permissions!: IAccountPermission[];
  token!: string;

  static fromJSON(json: any): AccountVerifyEmailModel {
    const model = AccountVerifyEmailModel.empty();

    Object.assign(model, {
      id: json.number,
      role: json.role,
      status: json.status,
      phoneNumber: json.phone_number,
      email: json.email,
      username: json.username,
      salesAccountId: json.sales_account_id,
      firstName: json.first_name,
      lastName: json.last_name,
      middleName: json.middle_name,
      contactName: json.contact_name,
      aaVersion: json.aa_version,
      currency: json.currency,
      permissions: json.permissions,
      token: json.token
    } as AccountVerifyEmailModel);

    return model;
  }

  static empty(): AccountVerifyEmailModel {
    return new AccountVerifyEmailModel();
  }
}

export enum AccountRoleEnum {
  Manager = 'manager',
  Customer = 'customer',
  Office = 'office',
  Qa = 'qa',
  Banner = 'banner',
  Admin = 'admin',
  Microservice = 'microservice',
  Csr = 'csr',
  Overflow = 'overflow'
}

export enum AvailableAccountRole {
  Manager = 'manager',
  Office = 'office',
  Qa = 'qa'
}

export enum AccountErosRole {
  Manager = 'manager',
  Customer = 'customer',
  Csr = 'csr',
  Overflow = 'overflow'
}

export enum AccountStatus {
  NotVerified = 'not verified',
  Active = 'active',
  Disabled = 'disabled'
}

export enum AccountDisableType {
  Default = '',
  Closed = 'closed',
  Frozen = 'frozen'
}

export enum AccountCloseReason {
  AOPSContent = 'AOPS CONTENT',
  AssociationToClosedAccount = 'ASSOCIATION TO CLOSED ACCOUNT',
  FraudId = 'FRAUD ID'
}

export enum AccountRequirement {
  Id = 'id',
  AuthPhoto = 'auth photo'
}

export enum AccountFlag {
  Test = 'test',
  DisableAutoPublish = 'disable autopublish'
}

export enum AccountPreference {
  SocialMedia = 'social media',
  AllowArchives = 'allow archives',
  EmailWatchlist = 'email watchlist',
  LinkAds = 'link ads',
  PreventAutomatedEmails = 'prevent automated emails'
}

export type AccountCloseReqPayload = {
  disable_reason: string;
  notify_account?: boolean;
  refund_amount?: number;
  paid_by_BTC?: boolean;
};

export type OpsAccountCreateReqPayload = {
  email: string;
  username: string;
  password: string;
  role: AccountRoleEnum;
  secret_pin?: number;
  phone_number?: number;
  contact_name?: string;
};

export type AccountCreateContactReqPayload = {
  email: string;
  comment: string;
  account_name?: string;
  contact_name?: string;
  phone_number?: string;
  reason?: string;
  account_id?: number;
};

export type AccountCreateFeedbackReqPayload = {
  featureId: number;
  rate: number;
  note?: string;
};

export type AccountFeedbackResPayload = {
  rate: number;
  note: string;
};

export type AccountFollowReqPayload = {
  account_id: number;
  follow_reason: string;
  actions: string[];
};

export enum AccountNotificationSeen {
  Seen = 'Seen',
  Unseen = 'Unseen'
}

export type AccountNotificationReqPayload = {
  accountId: number | null;
  limit: number;
  offset: number;
  seen: boolean | null;
  is_seen: boolean | null;
  type: AccountNotificationType;
  order_field: AccountNotificationOrderField | null;
  order: AccountNotificationOrder | null;
};

export type AccountRecoverPasswordReqPayload = {
  hash: string;
  email: string;
  password: string;
};

export type AccountUpdatePasswordReqPayload = {
  old_password?: string;
  new_password?: string;
  secret_pin?: string;
  security_question?: string;
  security_answer?: string;
};

export enum AccountNotificationOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AccountNotificationOrderField {
  DateCreated = 'date_created',
  DateSeen = 'date_seen',
  IsSeen = 'is_seen'
}

export interface IAccountPermission {
  manager: string[];
  customer: string[];
  sales: string[];
  office: string[];
  executive: string[];
  qa: string[];
  banner: string[];
}

export type AccountCheckVerificationCodeReqPayload = {
  jwt: string;
  account_id: number;
  verification_code: string;
  device_status?: AccountCheckVerificationCodeDeviceStatus;
};

export enum AccountCheckVerificationCodeDeviceStatus {
  Verified = 'verified',
  VerifiedNotTrusted = 'verified_not_trusted'
}

export enum AccountTokenDeviceStatus {
  NotVerified = 'not_verified',
  Verified = 'verified',
  VerifiedNotTrusted = 'verified_not_trusted'
}

export enum AccountNotificationType {
  AdvSubmitted = 'adv_submitted',
  DocUploaded = 'doc_uploaded'
}

export enum PermissionsEnum {
  AccountStatusChange = 'account.status.change',
  AccountResetAgreementUnassigned = 'account.reset-agreement.unassigned',
  AccountResetAgreementAssigned = 'account.reset-agreement.assigned',
  AccountResendVerificationEmail = 'account.resend-verification-email',
  AccountResendVerificationEmailAssigned = 'account.resend-verification-email.assigned',
  AccountResendVerificationEmailUnassigned = 'account.resend-verification-email.unassigned',
  AccountResetAgreement = 'account.reset-agreement',
  CommerceBalanceHistoryListRead = 'commerce.balance.history.list.read',
  CommerceCrmRead = 'commerce.crm.read',
  ReportPerformanceRead = 'report.performance.read',
  CommercePremiumServiceChangeInitiator = 'commerce.premium-service.change-initiator',
  CommercePremiumAdCancelRefund = 'commerce.premium-ad.cancel-refund',
  FraudIpRead = 'fraud.ip.read',
  TicketAssign = 'ticket.assign',
  TicketRead = 'ticket.read',
  TicketReadProcess = 'ticket.read.process',
  TicketReadReview = 'ticket.read.review',
  TicketReadAssigned = 'ticket.read.assigned',
  TicketReadUnAssigned = 'ticket.read.unassigned',
  TicketEscalate = 'ticket.escalate',
  TicketTake = 'ticket.take',
  TicketResolve = 'ticket.resolve',
  StoryRead = 'story.read',
  StoryTicketRead = 'story.ticket.read',
  StoryTicketTake = 'story.ticket.take',
  StoryTicketUntake = 'story.ticket.untake',
  StoryApprove = 'story.approve',
  StoryDelete = 'story.delete',
  StoryDecline = 'story.decline'
}

export class AccountRevenueModel {
  accountId: number;
  revenue: number;
  psRevenue: number;

  static fromJson(json): AccountRevenueModel {
    return {
      accountId: json.key,
      revenue: json.sum.value,
      psRevenue: json.revenue_ps.sum.value
    };
  }
}

export class AccountToken {
  token: string;
  permissions: string[];
  resetPassword: boolean;
  id: number;

  static fromJson(json): AccountToken {
    return {
      token: json.token,
      permissions: json.permissions,
      resetPassword: json.reset_password,
      id: json.id
    };
  }
}

export enum VerificationOptionEnum {
  Email = 'email',
  App = 'app'
}
