import { SetOptional } from 'type-fest';
import { AccountRoleEnum } from '@eros-api/models/account.model';

export class AbsenceCalendarEventModel {
  id!: string;
  accountId!: number;
  action!: string;
  description!: string;
  peerAccountId!: number[];
  dates!: { from: Date; to: Date };
  dateCreated!: Date;
  modifiedBy!: number | null;
  dateModified!: Date | null;
  actionObj?: AbsenceCalendarActionModel | null;

  static fromJSON(json: any): AbsenceCalendarEventModel {
    const model = AbsenceCalendarEventModel.empty();

    Object.assign(model, {
      id: json._id,
      accountId: json.account_id,
      action: json.action,
      description: json.description,
      peerAccountId: json.peer_account_id ?? [],
      dates: {
        from: json.dates.from,
        to: json.dates.to
      },
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      modifiedBy: json.modified_by ?? null
    } as AbsenceCalendarEventModel);

    return model;
  }

  static toJSONCreateModel(
    data: AbsenceCalendarEventCreateReqPayload
  ): Record<string, any> {
    const rec: Record<string, any> = {
      action: data.action,
      description: data.description,
      dates: data.dates
    };

    if (data.accountId) {
      rec['account_id'] = data.accountId;
    }

    if (data.peerAccountId) {
      rec['peer_account_id'] = data.peerAccountId;
    }

    return rec;
  }

  static toJSONUpdateModel(
    data: AbsenceCalendarEventUpdateReqPayload
  ): Record<string, any> {
    const rec: Record<string, any> = {
      action: data.action,
      description: data.description,
      dates: data.dates
    };

    if (data.accountId) {
      rec['account_id'] = data.accountId;
    }

    if (data.peerAccountId) {
      rec['peer_account_id'] = data.peerAccountId;
    }

    return rec;
  }

  static empty(): AbsenceCalendarEventModel {
    return new AbsenceCalendarEventModel();
  }
}

export type AbsenceCalendarEventsListReqPayload = {
  accountId?: number;
  dateFrom: string;
  dateTo: string;
};

export type AbsenceCalendarEventCreateReqPayload = SetOptional<
  Pick<
    AbsenceCalendarEventModel,
    'accountId' | 'action' | 'description' | 'peerAccountId' | 'dates'
  >,
  'accountId' | 'peerAccountId'
>;

export type AbsenceCalendarEventUpdateReqPayload = SetOptional<
  Pick<
    AbsenceCalendarEventModel,
    'accountId' | 'action' | 'description' | 'peerAccountId' | 'dates'
  >,
  'accountId' | 'peerAccountId'
>;

export class AbsenceCalendarActionModel {
  id!: string;
  title!: string;
  color!: string;
  createdBy!: number;
  modifiedBy!: number | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  ciTitle!: string | null;
  isDeleted!: boolean | null;
  duration?: number;
  position?: number;

  static fromJSON(json: any): AbsenceCalendarActionModel {
    const model = AbsenceCalendarActionModel.empty();

    Object.assign(model, {
      id: json._id,
      title: json.title,
      color: json.color,
      createdBy: json.created_by,
      modifiedBy: json.modified_by || null,
      dateCreated: new Date(json.date_created),
      ciTitle: json.ci_title,
      isDeleted: json.is_deleted,
      duration: json.duration,
      position: json.position,
      dateModified: json.date_modified ? new Date(json.date_modified) : null
    } as AbsenceCalendarActionModel);

    return model;
  }

  static empty(): AbsenceCalendarActionModel {
    return new AbsenceCalendarActionModel();
  }
}

export class ConfigModel {
  description: {
    min: number;
    max: number;
  };
  holidaysCount: number;
  static fromJSON(json: any): ConfigModel {
    return {
      description: {
        min: json.description.min,
        max: json.description.max
      },
      holidaysCount: json.holidays_count
    };
  }
}

export class DashboardDataModel {
  id: string;
  duration: number;
  static fromJSON(json: any): DashboardDataModel {
    return {
      id: json._id,
      duration: json.duration
    };
  }
}

export class HolidaysLeftModel {
  id: string;
  duration: number;
  static fromJSON(json: any): HolidaysLeftModel {
    return {
      id: json._id,
      duration: json.duration
    };
  }
}

export enum ActionEnum {
  Training = 'training',
  Holiday = 'holiday',
  OutOfOffice = 'out_of_office_unpaid_leave',
  Overtime = 'overtime',
  OfficeClosed = 'office_closed'
}

export type AbsenceCalendarActionCreateReqPayload = {
  title: string;
  color: string;
};

export type AbsenceCalendarActionUpdateReqPayload = {
  title: string;
  color: string;
};

export interface ManageAbsenceCalendarEventModel {
  dates: { from: Date; to: Date };
  description: string;
  account_id?: number;
  action: string;
  peer_account_id: string[];
}

export interface EventMemberModel {
  name: string;
  value: string;
  role?: AccountRoleEnum;
  disabled?: boolean;
}
