import { OpsAccountModel } from '@eros-api/models';

export function formatReferenceName(account: OpsAccountModel): string {
  const names = [];

  if (account.firstName) {
    names.push(account.firstName);
  }

  if (account.lastName) {
    names.push(account.lastName);
  }

  if (names.length) {
    return names.join(' ');
  }

  return account.username || account.email;
}
