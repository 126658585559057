import {
  ProfileAffiliation,
  ProfileAlternativeSpecialty,
  ProfileAvailableForModel,
  ProfileAvailableTo,
  ProfileCupSize,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileMainCategory,
  ProfilePhoneNumberModel,
  ProfileSocialModel,
  ProfileStyle,
  ProfileTransOperation,
  ProfileUnit
} from './profile.model';
import { ProfilePhotoModel } from '@eros-api/models/profile-photo.model';

export class ProfileRevisionModel {
  id!: string;
  affiliation!: ProfileAffiliation | null;
  age!: number;
  alternativeSpecialty!: ProfileAlternativeSpecialty[] | null;
  availableFor!: ProfileAvailableForModel[] | null;
  availableTo!: ProfileAvailableTo[] | null;
  bioText!: string | null;
  bustSize!: number | null;
  cupSize!: ProfileCupSize | null;
  disclaimer!: string | null;
  donations!: string | null;
  emails!: string[] | null;
  ethnicities!: ProfileEthnicity[] | null;
  eyeColor!: ProfileEyeColor | null;
  gender!: ProfileGender;
  hairColor!: ProfileHairColor | null;
  height!: number | null;
  hipSize!: number | null;
  inventoryId!: number;
  isAnimated!: boolean;
  isVip!: boolean;
  isVisiting!: boolean;
  locationCategories!: string[] | null;
  locationId!: number;
  locationTags!: string | null;
  magazineReference!: string | null;
  mainCategories!: ProfileMainCategory[];
  movieReference!: string | null;
  offersList!: string | null;
  phoneNumbers!: ProfilePhoneNumberModel[] | null;
  photos!: Array<{ id: string }>;
  profileId!: string;
  revisionMeta!: ProfileRevisionMetaModel;
  showname!: string;
  social!: ProfileSocialModel[];
  specialtyCategories!: string[] | null;
  style!: ProfileStyle;
  tagline!: string;
  transOperation!: ProfileTransOperation | null;
  unit!: ProfileUnit;
  visitingText!: string | null;
  waistSize!: number | null;
  websites!: string[] | null;
  weight!: number | null;
  mainPhoto!: ProfilePhotoModel | null;
  status!: string | null;
  number!: number | null;
  createdBy!: number | null;
  processedBy!: number | null;
  isModifiable!: boolean | null;
  isApproval!: boolean | null;
  dateCreated!: Date | null;
  dateProcessed!: Date | null;

  static fromJSON(json: any): ProfileRevisionModel {
    const model = new ProfileRevisionModel();

    Object.assign(model, {
      id: json._id,
      affiliation: json.affiliation,
      age: json.age,
      alternativeSpecialty: json.alternative_specialty,
      availableFor: json.available_for
        ? json.available_for.map((aFor: ProfileAvailableForModel) =>
            ProfileAvailableForModel.fromJSON(aFor)
          )
        : null,
      availableTo: json.available_to,
      bioText: json.bio_text,
      bustSize: json.bust_size,
      cupSize: json.cup_size,
      disclaimer: json.disclaimer,
      donations: json.donations,
      emails: json.emails,
      ethnicities: json.ethnicities,
      eyeColor: json.eye_color,
      gender: json.gender,
      hairColor: json.hair_color,
      height: json.height,
      hipSize: json.hip_size,
      inventoryId: json.inventory_id,
      isAnimated: json.is_animated,
      isVip: json.is_vip,
      isVisiting: json.is_visiting,
      locationCategories: json.location_categories,
      locationId: json.location_id,
      locationTags: json.location_tags,
      magazineReference: json.magazine_reference,
      mainCategories: json.main_categories,
      movieReference: json.movie_reference,
      offersList: json.offers_list,
      phoneNumbers: json.phone_numbers
        ? json.phone_numbers.map((num: ProfilePhoneNumberModel) =>
            ProfilePhoneNumberModel.fromJSON(num)
          )
        : null,
      photos: json.photos,
      profileId: json.profile_id,
      revisionMeta: ProfileRevisionMetaModel.fromJSON(json.revision),
      showname: json.showname,
      social: json.social
        ? json.social.map((social: ProfileSocialModel) =>
            ProfileSocialModel.fromJSON(social)
          )
        : null,
      specialtyCategories: json.specialty_categories,
      style: json.style,
      tagline: json.tagline,
      transOperation: json.trans_operation,
      unit: json.unit,
      visitingText: json.visiting_text,
      waistSize: json.waist_size,
      websites: json.websites,
      weight: json.weight,
      mainPhoto: json.photos?.[0],
      status: json.status,
      number: json.number,
      createdBy: json.created_by,
      processedBy: json.processed_by,
      isModifiable: json.is_modifiable,
      isApproval: json.is_approvable,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateProcessed: json.date_processed ? new Date(json.date_processed) : null
    } as ProfileRevisionModel);

    return model;
  }

  static toJSON(model: Partial<ProfileRevisionModel>): Record<string, any> {
    return {
      profile_id: model.profileId,
      showname: model.showname,
      tagline: model.tagline,
      visiting_text: model.visitingText,
      bio_text: model.bioText,
      offers_list: model.offersList,
      donations: model.donations,
      location_tags: model.locationTags,

      phone_numbers: model.phoneNumbers,
      emails: model.emails,
      websites: model.websites,
      social: model.social,

      magazine_reference: model.magazineReference,
      movie_reference: model.movieReference
    };
  }

  static empty(): ProfileRevisionModel {
    return new ProfileRevisionModel();
  }
}

export class ProfileRevisionMetaModel {
  createdBy!: number;
  dateCreated!: Date;
  dateModified!: Date | null;
  dateRevoked!: Date | null;
  isApprovable!: boolean;
  isDeleted!: boolean;
  isModifiable!: boolean;
  isRevoked!: boolean;
  modifiedBy!: number | null;
  number!: number;
  status!: any;
  dateProcessed!: Date;
  processedBy!: number;
  //status!: any; ProfileRevisionStatus | ProfilePhotoStatus

  static fromJSON(json: any): ProfileRevisionMetaModel {
    const model = ProfileRevisionMetaModel.empty();

    if (!json) {
      return model;
    }

    Object.assign(model, {
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      dateRevoked: json.date_revoked ? new Date(json.date_revoked) : null,
      isApprovable: json.is_approvable,
      isDeleted: json.is_deleted,
      isModifiable: json.is_modifiable,
      isRevoked: json.is_revoked,
      modifiedBy: json.modified_by || null,
      number: json.number,
      status: json.status,
      dateProcessed: json.date_processed,
      processedBy: json.processed_by
    } as ProfileRevisionMetaModel);

    return model;
  }

  static empty(): ProfileRevisionMetaModel {
    return new ProfileRevisionMetaModel();
  }
}

export class ProfileRevisionByIdResPayload {
  id: string;
  revision: { number: number };

  static fromJSON(json: any): ProfileRevisionByIdResPayload {
    return {
      id: json._id,
      revision: json.revision
    };
  }
}
