import { RequireExactlyOne } from 'type-fest';
import { MediaImageModel, ThumbNameEnum } from '@eros-api/models/media.model';

export class ProfilePhotoModel {
  id!: string;
  accountId!: number;
  personId!: string;
  imageId!: string;
  dateCreated!: Date;
  dateProcessed!: Date | null;
  dateLastUsed!: Date | null;
  status!: ProfilePhotoStatus;
  processedBy!: number | null;
  media: MediaImageModel;
  isDeleted!: boolean;
  number!: number;
  isNew!: boolean;
  markedAopsBy!: number | null;
  stampedBy: number | null;
  isMain: boolean;

  static fromJSON(json: any): ProfilePhotoModel {
    const model = ProfilePhotoModel.empty();

    Object.assign(model, {
      id: json._id,
      accountId: json.account_id,
      personId: json.person_id,
      imageId: json.image_id,
      dateCreated: new Date(json.date_created),
      dateProcessed: json.date_processed ? new Date(json.date_processed) : null,
      dateLastUsed: json.date_last_used ? new Date(json.date_last_used) : null,
      status: json.status,
      processedBy: json.processed_by || null,
      isDeleted: json.is_deleted,
      number: json.number,
      isNew: json.is_new,
      isMain: json.is_main,
      stampedBy: json.stamped_by ?? null,
      markedAopsBy: json.marked_aops_by ?? null
    } as ProfilePhotoModel);

    return model;
  }

  static empty(): ProfilePhotoModel {
    return new ProfilePhotoModel();
  }
}

export enum ProfilePhotoStatus {
  Pending = 'pending',
  Declined = 'declined',
  Approved = 'approved'
}

export type ProfilePhotoCreateReqPayload = {
  accountId: number;
  personId: string;
} & RequireExactlyOne<{ imageId: string; imageIds: string[] }>;

export type ProfilePhotoCreateResPayload = Array<{
  photoId: string;
  imageId: string;
  number: number;
}>;

export type ProfilePhotoFindManyByIdsPayload = {
  accountId: number;
  photoIds: string[];
  personId?: string;
  showDeleted?: boolean;
  thumbName?: ThumbNameEnum;
};
