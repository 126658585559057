import { AccountModel, OpsAccountModel } from '@eros-api/models/account.model';

export class FlagWordsModel {
  id!: string;
  type!: FlagWordType;
  value!: string;
  createdBy?: number;
  createdByObj?: AccountModel | OpsAccountModel;
  dateCreated!: Date;
  modifiedBy?: number | null;
  modifiedByObj?: AccountModel;
  dateModified?: Date | null;
  accountId?: number | null;

  static fromJSON(json: IFlagWordResModel): FlagWordsModel {
    const model = FlagWordsModel.empty();

    Object.assign(model, {
      id: json._id,
      type: json.type,
      value: json.value,
      createdBy: json.created_by,
      createdByObj: json.created_by_obj,
      modifiedByObj: json.modified_by_obj,
      dateCreated: new Date(json.date_created),
      modifiedBy: json.modified_by ? json.modified_by : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      accountId: json.account_id ? json.account_id : null
    } as FlagWordsModel);

    return model;
  }

  static empty(): FlagWordsModel {
    return new FlagWordsModel();
  }
}

export interface IFlagWordResModel {
  _id: string;
  type: FlagWordType;
  value: string;
  created_by: number;
  date_created: Date;
  modified_by?: number;
  date_modified?: Date;
  account_id?: number;
  created_by_obj?: AccountModel;
  modified_by_obj?: AccountModel;
}

export enum FlagWordType {
  Empty = '',
  Blocked = 'blocked',
  BlockedException = 'blocked exception',
  Flagged = 'flagged',
  FlaggedException = 'flagged exception',
  Host = 'host',
  BadLegalName = 'bad legal name',
  BadPhoneNumber = 'bad phone number',
  BadEmail = 'bad email',
  BadWebsite = 'bad website',
  BadIdNumber = 'bad id number',
  BadIdName = 'bad id name'
}

export enum KeyToFlagWordType {
  PhoneNumbers = 'phone_numbers',
  PhoneNumber = 'phone_number',
  Emails = 'emails',
  Email = 'email',
  IdNumber = 'id_number',
  Websites = 'websites',
  AccountFullName = 'account_full_name',
  RevisionFullName = 'revision_full_name',
  DocumentFullName = 'document_full_name'
}

export type FlagWordCreateReqPayload = {
  type: FlagWordType;
  value: string;
  dateCreated: Date;
  id: string;
  createdByName: string;
};

export type MarkAsBadFlagWord = {
  type: FlagWordType;
  value: string;
  account_id: number;
};

export class TemplateModel {
  id!: string;
  type!: TemplateType;
  body!: string;
  createdBy: number;
  dateCreated!: Date;
  dateModified?: Date | null;
  modifiedBy?: number | null;
  subject?: string | null;
  title: string;
  availability: string[];

  static fromJSON(json: ITemplateResModel): TemplateModel {
    const model = TemplateModel.empty();

    Object.assign(model, {
      id: json._id,
      type: json.type,
      body: json.body,
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      modifiedBy: json.modified_by ? json.modified_by : null,
      subject: json.subject ? json.subject : null,
      title: json.title,
      availability: json.availability
    } as TemplateModel);

    return model;
  }

  static empty(): TemplateModel {
    return new TemplateModel();
  }
}

export interface ITemplateResModel {
  _id: string;
  type: TemplateType;
  body: string;
  created_by: number;
  date_created: Date;
  modified_by?: number;
  date_modified?: Date;
  subject?: string;
  title: string;
  availability: string[];
}

export enum TemplateType {
  Alert = 'alert',
  Message = 'message'
}

export class EgTermModel {
  id!: string;
  use!: EgTermType;
  title: string;
  possibleDefinition!: string | null;
  modifiedBy?: number | null;
  lowercaseTitle!: string;
  exception!: string | null;
  createdBy: number;
  dateCreated!: Date;
  dateModified?: Date | null;

  static fromJSON(json: IEgTermResModel): EgTermModel {
    const model = EgTermModel.empty();

    Object.assign(model, {
      id: json._id,
      use: json.use,
      title: json.title,
      possibleDefinition: json.possible_definition
        ? json.possible_definition
        : null,
      modifiedBy: json.modified_by ? json.modified_by : null,
      lowercaseTitle: json.lowercase_title,
      exception: json.exception ? json.exception : null,
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified ? new Date(json.date_modified) : null
    } as EgTermModel);

    return model;
  }

  static toJSONUpdateModel(model: EgTermUpdateReqPayload): Record<any, string> {
    return {
      id: model.id,
      title: model.title,
      possible_definition: model.possibleDefinition,
      use: model.use ? model.use : null,
      exception: model.exception ? model.exception : null
    };
  }

  static toJSONCreateModel(model: EgTermCreateReqPayload): Record<any, string> {
    return {
      title: model.title,
      possible_definition: model.possibleDefinition,
      use: model.use ? model.use : null,
      exception: model.exception ? model.exception : null
    };
  }

  static empty(): EgTermModel {
    return new EgTermModel();
  }
}

export enum EgTermType {
  AOPS = 'AOPS',
  OK = 'OK'
}

export interface IEgTermResModel {
  _id: string;
  use: EgTermType;
  title: string;
  possible_definition: string | null;
  modified_by?: number;
  lowercase_title: string;
  exception: string | null;
  created_by: number;
  date_created: Date;
  date_modified?: Date;
}

export type EgTermUpdateReqPayload = Pick<
  EgTermModel,
  'id' | 'title' | 'use' | 'possibleDefinition' | 'exception'
>;

export type EgTermCreateReqPayload = Pick<
  EgTermModel,
  'title' | 'use' | 'possibleDefinition' | 'exception'
>;

export enum NotificationMessageType {
  Message = 'message'
}

export interface ISendNotificationMessageReqModel {
  account_id: number;
  body: string;
  type: NotificationMessageType;
  subject?: string;
}

export interface IFindFlagWordsByTypeValueReqModel {
  type: FlagWordType;
  value: string;
}

export interface IFindFlagWordsByTypeValuesReqModel {
  type: FlagWordType;
  values: string[];
}

export interface EgTermsTokens {
  value: string;
  terms: EgTermModel[] | null;
}
