import { EnvironmentTarget } from './environment-target';

export const environment = {
  target: EnvironmentTarget.Dev,
  production: false,
  apiUrl: 'https://dev-api.eros.com',
  interiorUrl: 'https://dev.erosads.com',
  exteriorUrl: 'https://dev.eros.com',
  contentServerUrl: 'https://dev-i.eros.com',
  socksUrl: 'https://dev-api.eros.com/cellar',
  toyboxUrl: 'https://toybox.prod.eros.systems',
  shuftiproUrl: 'https://backoffice.shuftipro.com/verifications',
  sumSubUrl:
    'https://test-api.sumsub.com/checkus#/applicant/{{personId}}/basicInfo?clientId=eros'
};
