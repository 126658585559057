import { ProfilePersonModel } from '@eros-api/models/profile-person.model';
import {
  MediaDocumentModel,
  MediaDocumentPurpose
} from '@eros-api/models/media.model';

export class ProfileDocumentModel {
  id!: string;
  personId!: string | null;
  documents!: Array<{
    id: string;
    degree?: number;
    flip_h?: boolean;
    flip_v?: boolean;
  }>;
  countryCode!: string | null;
  idScanStatus!: string | null;
  idType!: ProfileDocumentIdType | null;
  instructions!: string | null;
  isAcceptable!: boolean | null;
  status!: ProfileDocumentStatus;
  type!: ProfileDocumentType;
  verificationStatus!: string | null;
  verifiedBy!: string | null;
  thirdPartyVerified!: ProfileDocumentThirdPartyVerified;
  firstName!: string | null;
  middleName!: string | null;
  lastName!: string | null;
  birthDate!: Date | null;
  idNumber!: string | null;
  expirationDate!: Date | null;
  dateCreated!: Date;
  dateModified!: Date | null;
  createdBy!: number;
  modifiedBy!: number;
  modifiedByAccountName!: string;
  approvedDeclinedBy!: number;
  approvedDeclinedByName!: string;
  firstApprovedBy!: number;
  firstApprovedByName!: string;
  accountId!: number;
  salesAccountId!: number;
  person!: ProfilePersonModel | null;
  isPassedLiveness: boolean;
  declinedReason: string;
  declineReason: string;
  photos: DocumentPhotoModel[];
  applicantId!: string | null;
  completed: boolean;
  scanRefId?: string;

  static fromJson(json: any): ProfileDocumentModel {
    const model = ProfileDocumentModel.empty();
    Object.assign(model, {
      id: json._id,
      personId: json.person_id,
      documents: json.documents,
      countryCode: json.country_code ?? null,
      idScanStatus: json.id_scan_status ?? null,
      idType: json.id_type ?? null,
      instructions: json.instructions ?? null,
      isAcceptable: json.is_acceptable ?? null,
      status: json.status,
      type: json.type,
      scanRefId: json.scan_ref_id,
      verificationStatus: json.verification_status ?? null,
      verifiedBy: json._verified_by ?? null,
      thirdPartyVerified: json.third_party_verified,
      firstName: json.first_name ?? null,
      middleName: json.middle_name ?? null,
      lastName: json.last_name ?? null,
      birthDate: json.birth_date ? new Date(json.birth_date) : null,
      idNumber: json.id_number ?? null,
      expirationDate: json.expiration_date
        ? new Date(json.expiration_date)
        : null,
      dateCreated: new Date(json.date_created),
      dateModified: json.date_modified
        ? new Date(json.date_modified)
        : json.date_modified,
      createdBy: json.created_by,
      modifiedBy: json.modified_by ?? null,
      approvedDeclinedBy: json.approved_declined_by
        ? json.approved_declined_by
        : null,
      firstApprovedBy: json.first_approved_by ?? null,
      accountId: json.account_id,
      salesAccountId: json.sales_account_id,
      person: json.person ? ProfilePersonModel.fromJSON(json.person) : null,
      isPassedLiveness: !!json.is_passed_liveness,
      declinedReason: (() => {
        if (json.declined_reason) {
          // hot fix
          return json.declined_reason === 'Image is altered or photoshopped'
            ? 'Image is altered or photoshopped'
            : json.declined_reason;
        } else {
          return null;
        }
      })(),
      declineReason: json.decline_reason ? json.decline_reason : null,
      photos: json.documents.map((document) =>
        DocumentPhotoModel.fromJSON(document)
      ),
      applicantId: json.applicant_id ?? null,
      completed: false
    } as ProfileDocumentModel);

    return model;
  }

  static toJson(model: Partial<ProfileDocumentModel>): { [key: string]: any } {
    return {
      person_id: model.personId, // required
      documents: model.documents, // required
      account_id: model.accountId,
      id: model.id,
      country_code: model.countryCode,
      id_scan_status: model.idScanStatus,
      id_type: model.idType,
      instructions: model.instructions,
      is_acceptable: model.isAcceptable,
      status: model.status,
      type: model.type, // required
      verification_status: model.verificationStatus,
      _verified_by: model.verifiedBy,
      third_party_verified: model.thirdPartyVerified,
      first_name: model.firstName,
      middle_name: model.middleName,
      last_name: model.lastName,
      birth_date: model.birthDate,
      id_number: model.idNumber,
      expiration_date: model.expirationDate,
      decline_reason: model.declineReason
    };
  }

  static empty(): ProfileDocumentModel {
    return new ProfileDocumentModel();
  }
}

export enum ProfileDocumentType {
  ID = 'id',
  AuthenticationPhoto = 'authentication photo',
  UnalteredPhoto = 'unaltered photo'
}

export enum ProfileDocumentVerifiedBy {
  Sumsub = 'sumsub',
  Idenfy = 'idenfy'
}

export enum ProfileDocumentIdType {
  Passport = 'PASSPORT',
  IdCard = 'ID_CARD',
  DrivingLicense = 'DRIVING_LICENSE'
}

export enum ProfileDocumentAdditionalDocInfo {
  CountryCode = 'Country Code',
  IDType = 'ID Type',
  IDScanStatus = 'ID Scan Status',
  DeclineReason = 'Decline Reason'
}

export enum ProfileDocumentAdditionalDocInfoType {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  ERROR = 'ERROR'
}

export enum ProfileDocumentStatus {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  NotVerified = 'not_verified',
  Expired = 'expired'
}

export enum ProfileDocumentStatusPreview {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined'
}

export enum ProfileDocumentDeclineReasonTypes {
  ReadableDocument = 'Not readable document',
  MismatchingDocument = 'Mismatching document',
  FraudulentID = 'Fraudulent ID',
  Expired = 'Expired',
  NotAcceptableWeRequirePassport = 'Not acceptable, we require a Passport'
}

export enum ProfileDocumentThirdPartyVerified {
  Yes = 'yes',
  No = 'no',
  NA = 'n/a'
}

export type ICreateManyDocumentsResPayload = Array<{
  id: string;
  person_id: string;
  type: ProfileDocumentType;
  documents: Array<{ id: string }>;
}>;

export type CreateMediaDocumentImageReqPayload = {
  files: File[];
  purpose: MediaDocumentPurpose;
  accountId?: number;
};

export interface ISumsubCallbackResponse {
  reviewResult: { reviewAnswer: string };
  applicantId: string;
  inspectionId: string;
  applicantType: string;
  correlationId: string;
  externalUserId: string;
  reviewStatus: string;
  type: string;
  info: {
    firstName: string;
    firstNameEn: string;
    lastName: string;
    lastNameEn: string;
    dob: string;
    gender: string;
    country: string;
    idDocs: Array<{
      idDocType: string;
      country: string;
      firstName: string;
      firstNameLocal: string;
      firstNameEn: string;
      lastName: string;
      lastNameLocal: string;
      lastNameEn: string;
      issuedDate: string;
      validUntil: string;
      number: string;
      dob: string;
      gender: string;
      mrzLine1: string;
      mrzLine2: string;
      mrzLine3: string;
    }>;
  };
}

export type ISumsubGetCountriesResponse = Array<{
  _id: string;
  name: string;
  country_code_long: string;
  country_code_short: string;
  allowed_documents: Array<
    | Lowercase<ProfileDocumentIdType.Passport>
    | Lowercase<ProfileDocumentIdType.IdCard>
    | Lowercase<ProfileDocumentIdType.DrivingLicense>
  >;
}>;

export class DocumentPhotoModel {
  id = '';
  degree = '';
  flip: { h: string; v: string } = { h: '', v: '' };

  documentMedia: MediaDocumentModel;

  toJSON() {
    const flip = [];

    if (this.flip.h === 'h') {
      flip.push('h');
    }

    if (this.flip.v === 'v') {
      flip.push('v');
    }

    return {
      id: this.id,
      degree:
        this.degree === 'right' ? 90 : this.degree === 'left' ? -90 : undefined,
      flip: flip.length ? flip : undefined
    };
  }

  static fromJSON(json: any): DocumentPhotoModel {
    const documentPhotoModel = new DocumentPhotoModel();

    Object.assign(documentPhotoModel, {
      id: json.id,
      degree: json.degree === 90 ? 'right' : json.degree === -90 ? 'left' : '',
      flip: {
        h: json.flip && json.flip.includes('h') ? 'h' : '',
        v: json.flip && json.flip.includes('v') ? 'v' : ''
      }
    });

    return documentPhotoModel;
  }

  static empty(): DocumentPhotoModel {
    return new DocumentPhotoModel();
  }
}

export type ChangeDocumentStatusReqPayload = {
  accountId: number;
  documentIds: string[];
  status: ProfileDocumentStatus;
};
