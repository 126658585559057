export class ProfilePersonModel {
  id!: string;
  name!: string;
  accountId!: number;
  dateCreated!: Date;
  flag!: string;
  idBypass!: boolean;
  verificationStatus!: PersonVerificationStatus;
  isDeactivated!: boolean;
  processedDate!: Date | null;
  processedBy!: number | null;
  isAccountHolder!: boolean;
  livenessVerificationStatus!: PersonLivenessVerificationStatus;
  agreement: {
    aaVersion: number;
    dateAAReset: Date | null;
  };

  static fromJSON(json: any): ProfilePersonModel {
    const model = ProfilePersonModel.empty();

    Object.assign(model, {
      id: json._id,
      name: json.name,
      accountId: json.account_id,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      flag: json.flag,
      idBypass: json.id_bypass,
      verificationStatus: json.verification_status,
      isDeactivated: Boolean(json.is_deactivated),
      processedDate: json.processed_date ? new Date(json.processed_date) : null,
      processedBy: json.processed_by || null,
      livenessVerificationStatus: json.liveness_verification_status,
      agreement: {
        aaVersion: json.agreement?.aa_version,
        dateAAReset: json.agreement?.date_aa_reset
          ? new Date(json.agreement?.date_aa_reset)
          : null
      },
      isAccountHolder: [
        'Account Holder Profile',
        'Account Holder Person'
      ].includes(json.name)
    } as ProfilePersonModel);

    return model;
  }

  static toJSON(model: ProfilePersonCreateReqPayload): Record<string, any> {
    return {
      name: model.name,
      account_id: model.accountId
    };
  }

  static empty(): ProfilePersonModel {
    return new ProfilePersonModel();
  }
}

export enum PersonVerificationStatus {
  Approved = 'approved',
  Declined = 'declined',
  NotVerified = 'not_verified'
}

export enum PersonLivenessVerificationStatus {
  Approved = 'approved',
  Declined = 'declined',
  NotVerified = 'not_verified',
  Requested = 'requested'
}

// API service Request and  Response types
export type ProfilePersonCreateReqPayload = Pick<
  ProfilePersonModel,
  'name' | 'accountId'
>;

export type ProfilePersonMergeResPayload = {
  src_person_data: ProfilePersonModel;
  dst_person_data: ProfilePersonModel;
  account_id: number;
  token_account_username: string;
  token_account_id: number;
};

export interface ProfileAttributesResponse {
  cup_size: CupSize;
  eye_color: EyeColor;
  alternative: Alternative;
  gender: Gender;
  hair_color: HairColor;
  availability: Availability;
  affiliation: Affiliation;
  op_status: OpStatus;
  ethnicity: Ethnicity;
}

export interface CupSize {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids?: any;
}

export interface EyeColor {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids?: any;
}

export interface Alternative {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids?: any;
}

export interface Gender {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids?: any;
}

export interface Categories {
  25: string;
  26: string;
  27: string;
}

export interface HairColor {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids: number[][];
  categories: Categories;
}

export interface ErosCategoryId {
  main_category: string;
  category_ids: number[];
  genders: string[];
}

export interface Categories2 {
  [key: number]: string;
}

export interface Availability {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids: ErosCategoryId[];
  categories: Categories2;
}

export interface Affiliation {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids: number[];
}

export interface OpStatus {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids?: any;
}

export interface Ethnicity {
  _id: string;
  type: string;
  values: string[];
  eros_ids: number[];
  eros_category_ids: number[];
}

export type PersonUpdateAuthenticatedReqPayload = {
  personId: string;
  isAuthenticated: boolean;
};
